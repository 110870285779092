import React from "react";
import i18n from "#translate/i18n";
import ImgRebase from "#images/new_logo_rebase.svg";
import BgLogin from "#images/background_login.svg";
import { UserFormLogin, FooterBar } from "#components";

export default function LoginUser() {
  return (
    <div>
      <img
        src={ BgLogin }
        className="w-[80%] fixed bg-login"
        alt="Rebase logo"
      />

      <div className="w-[41.2%] absolute flex flex-col bg-white right-0 bottom-[6.25%] top-0 overflow-y-auto">
        <img
          id="rebase-logo"
          src={ ImgRebase }
          className="mx-auto max-h-[15vh] xl:mt-[33px] 2xl:mt-[120px]"
          alt="Rebase logo"
        />

        <div className="w-full text-center mt-10">
          <h1 className="text-2xl font-bold">{ i18n.t("login.title") }</h1>
          <p className="font-medium mt-2">{ i18n.t("login.subtitle") }</p>
        </div>

        <UserFormLogin />
      </div>

      <FooterBar />
    </div>
  );
}
