import React from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import SelectInput from "#components/formComponents/SelectInput";
import HiddenInput from "#components/formComponents/HiddenInput";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import DateInput from "#components/formComponents/DateInput";
import CheckboxInput from "#components/formComponents/CheckboxInput";
import DivisionLine from "#components/formComponents/DivisionLine";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import BtnAdd from "../BtnAdd";
import BtnRemove from "../BtnRemove";

export default function CompanyHistoryRecordFields({ clientList }) {
  const model = "company_history_records";
  const { control, setValue, getValues, trigger } = useFormContext();
  useWatch({ control, name: model });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: model,
  });

  const newCompanyRecord = {
    project_name: "",
    client_id: "",
    squad_name: "",
    start_date: "",
    end_date: "",
    adjourning_motivation: "",
  };

  const displayRemoveButton = (companyHistoryRecord) => (
    !companyHistoryRecord.company_history_records_id || companyHistoryRecord.status !== 'removed'
  )

  const removeField = (companyHistoryRecord, index) => {
    if (!companyHistoryRecord.company_history_records_id)
      return () => remove(index);

    const statusField = `${model}.${index}.status`;

    // TODO: Find better way to hide element and update state
    return () => {
      update(index, { ...companyHistoryRecord, ["status"]: 'removed' });
      setValue(statusField, 'removed');
    };
  };

  const activeFields = fields.filter((field) => field.status !== 'removed');

  const handleChecked = (event, index) => {
    const checked = event.target.checked
    setValue(`${model}.${index}.on_going`, checked)
    if (checked) { setValue(`${model}.${index}.end_date`, "") }
    trigger([`${model}.${index}.end_date`])
  };

  return (
    <div>
      <DivisionTitle title={i18n.t("history").toUpperCase()} />

      {fields.length === 0 && (
        <div key='0' id='project-0' >
          <div className='grid grid-cols-2 gap-y-3 gap-x-9 mt-5' >
            <InputText
              model={model}
              name='0.project_name'
              title={i18n.t("company_history_record.project_name")}
              className='w-full'
            />

            <SelectInput
              model={model}
              name='0.client_id'
              options={clientList}
              title={i18n.t("company_history_record.client_id")}
              className='w-full'
            />
          </div>
          <div className="grid gap-y-3 gap-x-9 my-2.5">
            <InputText
              model={model}
              name='0.squad_name'
              title={i18n.t("company_history_record.squad_name")}
              className='w-full'
            />
          </div>
          <div className="grid grid-cols-2 gap-y-3 gap-x-9">
            <DateInput
              model={model}
              name='0.start_date'
              title={i18n.t("company_history_record.start_date")}
              classStyle='w-full'
            />

            <DateInput
              model={model}
              name='0.end_date'
              title={i18n.t("company_history_record.end_date")}
              classStyle='w-full'
            />
          </div>
          <div className="grid grid-cols-2 gap-y-0 gap-x-9" >
            <div />
            <CheckboxInput
              model={model}
              name={"0.on_going"}
              label={i18n.t("company_history_record.on_going")}
              inputDisabled={getValues(`${model}.0.end_date`)}
              id={`${model}.0.on_going_input`}
              checkboxInputDivClassName={'h-10 w-10 pt-[5px] pl-3'}
              labelClassName={'text-sm font-normal text-blue-gray font-sans'}
              errorClassName='ml-3 mb-2'
              index={0}
              onClick={(event, index) => handleChecked(event, index)}
            />
          </div>
          <div className="grid grid-cols-2 gap-y-3 gap-x-9" >
            <TextAreaInput
              model={model}
              name='0.adjourning_motivation'
              title={i18n.t("company_history_record.adjourning_motivation")}
              placeholder={i18n.t(
                "placeholders.company_history_record.adjourning_motivation"
              )}
              className='w-full'
            />

            <TextAreaInput
              model={model}
              name='0.tools_and_permissions'
              title={i18n.t("company_history_record.tools_and_permissions")}
              placeholder={i18n.t(
                "placeholders.company_history_record.tools_and_permissions"
              )}
              className='w-full'
            />

            <BtnAdd
              model={model}
              id='add-new-project-0'
              name={i18n.t("buttons.new_project")}
              style="mt-3"
              display={true}
              handleOnClick={() => append(newCompanyRecord)}
            />
          </div>
        </div>
      )}

      {activeFields.map((project, index) => (
        <div key={project.id} id={`project-${index}`} >
          {index > 0 && (
            <div className={`row-start-6 col-start-1 col-end-3`}>
              <DivisionLine />
            </div>
          )}
          <div className="grid grid-cols-2 gap-y-3 gap-x-9">
            <InputText
              model={model}
              name={`${index}.project_name`}
              title={i18n.t("company_history_record.project_name")}
              className='w-full'
            />

            <SelectInput
              model={model}
              name={`${index}.client_id`}
              options={clientList}
              title={i18n.t("company_history_record.client_id")}
              className='w-full'
            />
          </div>
          <div className="grid gap-y-3 gap-x-9 my-2.5" >
            <InputText
              model={model}
              name={`${index}.squad_name`}
              title={i18n.t("company_history_record.squad_name")}
              className='w-full'
            />
          </div>
          <div className="grid grid-cols-2 gap-y-3 gap-x-9" >
            <DateInput
              model={model}
              name={`${index}.start_date`}
              title={i18n.t("company_history_record.start_date")}
              classStyle='w-full'
            />

            <DateInput
              model={model}
              name={`${index}.end_date`}
              title={i18n.t("company_history_record.end_date")}
              classStyle='w-full'
              disabled={getValues(`${model}.${index}.on_going`)}
            />
          </div>
          <div className="grid grid-cols-2 gap-y-0 gap-x-9" >
            <div />
            <CheckboxInput
              model={model}
              name={`${index}.on_going`}
              label={i18n.t("company_history_record.on_going")}
              inputDisabled={getValues(`${model}.${index}.end_date`)}
              id={`${model}.${index}.on_going_input`}
              checkboxInputDivClassName={'h-10 w-10 pt-[5px] pl-3'}
              labelClassName={'text-sm font-normal text-blue-gray font-sans'}
              errorClassName='ml-3 mb-2'
              index={index}
              onClick={(event, index) => handleChecked(event, index)}
            />
          </div>
          <div className="grid grid-cols-2 gap-y-3 gap-x-9" >
            <TextAreaInput
              model={model}
              name={`${index}.adjourning_motivation`}
              title={i18n.t("company_history_record.adjourning_motivation")}
              placeholder={i18n.t(
                "placeholders.company_history_record.adjourning_motivation"
              )}
              className='w-full'
            />

            <TextAreaInput
              model={model}
              name={`${index}.tools_and_permissions`}
              title={i18n.t("company_history_record.tools_and_permissions")}
              placeholder={i18n.t(
                "placeholders.company_history_record.tools_and_permissions"
              )}
              className='w-full'
            />

            {project.company_history_records_id && (
              <HiddenInput model={model} name={`${index}.status`} />
            )}
            <div className={`${(activeFields.length - 1 === index) ? 'flex gap-6' : 'hidden'}`}>
              <BtnAdd
                model={model}
                id={`add-new-project-${index}`}
                name={i18n.t("buttons.new_project")}
                style="mt-3"
                display={activeFields.length - 1 === index}
                handleOnClick={() => append(newCompanyRecord)}
              />

              {index > 0 && displayRemoveButton(project) && (
                <BtnRemove
                  model={model}
                  id={`remove-project-${index}`}
                  name={i18n.t("buttons.remove_project")}
                  style="mt-3"
                  handleOnClick={removeField(project, index)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
