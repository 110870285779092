import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import i18n from "#translate/i18n";
import fetchHealthcareAdhesion from "#requests/users/fetchHealthcareAdhesion"
import LoadingPage from "#components/navigation/LoadingPage";

export default function HealthcareAdhesion() {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [healthcareAdhesionData, setHealthcareAdhesionData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchHealthcareAdhesion()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setHealthcareAdhesionData(parsedResponse)
        setloading(false)
      });
  }, []);

  const xValues = ['ratio_with_healthcare', 'ratio_without_healthcare']
  const yValues = () => {
    const sum = xValues.map((adhesion) => {
      const value = Number(healthcareAdhesionData[adhesion])
      return value * 100
    })
    return sum
  }

  const colors = ['#FBC62F', '#F75D5F']
  const data = {
    labels: xValues.map((adhesion) => i18n.t(`homePage.remunerations.healthcare_adhesion.legends.${adhesion}`)),
    datasets: [
      {
        data: yValues(),
        backgroundColor: colors,
        borderWidth: 0,
        cutout: "70%"
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 30,
          fullSize: true,
          color: '#2D2D2D'
        },
        onClick: (e) => e.stopPropagation()
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return `${tooltipItem.label}: ${Number(value).toFixed(2)}%`;
          }
        }
      },
    },
    layout: {
      padding: {
        top: -15,
        bottom: 20,
      },
    },
    animation: {
      onComplete: (animation) => {
        const chartInstance = animation.chart;
        const ctx = chartInstance.ctx;
        ctx.font = '500 12px Inter';
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        chartInstance.data.datasets[0].data.forEach((value, i) => {
          const meta = chartInstance.getDatasetMeta(0);
          const element = meta.data[i];
          if (!element || !element.circumference || value <= 0) return;
          const elementIndex = element.$context.index;
          const textColor = elementIndex === 0 ? '#000000' : '#FFFFFF';
          const { x, y } = element.tooltipPosition();
          ctx.fillStyle = textColor;
          ctx.fillText(`${Number(value).toFixed(0)}%`, x, y);
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div className="w-full lg:h-[380px] xl:h-[400px] 2xl:h-[437px]" id='users-allowances-distribution' >
      <div className="chart-container">
        <div className='border w-full h-full rounded-t h-[92px] bg-white'>
          <div className='flex flex-col w-full px-6 pt-6 pb-4 gap-2'>
            <p className='font-sans text-xl/6 font-semibold text-left text-black-report' >
              {i18n.t(`homePage.remunerations.healthcare_adhesion.title`)}
            </p>
            <p className='font-sans text-sm/5 font-normal text-left text-default-gray-2' >
              {i18n.t(`homePage.remunerations.healthcare_adhesion.subtitle`)}
            </p>
          </div>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className='border-l border-r border-b rounded-b mt-0 w-auto lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white'
          id='chart-healthcare-adhesion'
        >
          {
            loading ?
              <LoadingPage
                customHeight="h-full"
                customWidth="w-full"
                customImageClass={"w-10 h-10"}
                customTextClass={'hidden'}
              /> : (
                <Doughnut data={data} options={options} plugins={[customLegend]} />
              )
          }
        </div>
      </div>
    </div>
  )
}
