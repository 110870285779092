import React from "react";
import { useFormContext, get } from "react-hook-form";
import ErrorComponent from "#components/formComponents/ErrorComponent"

const CheckboxInput = ({
  model,
  name: inputName,
  label,
  inputDisabled,
  checkboxInputDivClassName,
  labelClassName,
  errorClassName,
  onClick,
  index,
  ...props
}) => {
  const name = model ? `${model}.${inputName}` : inputName;
  const id = props.id || name;
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const isUnchecked = errors?.firstGuestAccess?.terms_of_use
  const fieldError = get(errors, name);
  const invalidClassName = (fieldError && !inputDisabled && "ring-2 ring-red-500") || "";

  return (
    <div className="flex flex-col">
      <div className="flex items-center mt-0 gap-1" >
        <div className={checkboxInputDivClassName}>
          <input
            type="checkbox"
            disabled={inputDisabled}
            {...props}
            {...register(name)}
            id={id}
            className={`border-2 border-cloudy-blue ${isUnchecked ? 'focus:ring-red-500' : 'focus:ring-cloudy-blue'} rounded mr-2 disabled:bg-border-gray ${invalidClassName}`}
            onClick={(event) => onClick(event, index)}
          />
        </div>

        <label htmlFor={id} className={`text-blue-gray text-sm ${labelClassName}`}>
          {label}
        </label>
      </div>
      {
        fieldError && !inputDisabled &&
        <ErrorComponent
          message={fieldError.message}
          errorId={`${name}_error`}
          errorClassName={errorClassName}
          {...props}
        />
      }
    </div>
  );
};

export default CheckboxInput;
