/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import i18n from "#translate/i18n";
import fetchWorkFieldsDistribution from "#requests/users/fetchWorkFieldsDistribution"
import LoadingPage from "#components/navigation/LoadingPage";

export default function WorkFieldDistribution() {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [xValues, setXValues] = useState([]);
  const [yValues, setYValues] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setloading] = useState(true);

  const getLegendPadding = () => {
    const width = window.innerWidth;
    if (width >= 1536) { // 2xl breakpoint in Tailwind
      return 20;
    } else if (width >= 1280) { // xl breakpoint in Tailwind
      return 10;
    } else if (width >= 1024) { // lg breakpoint in Tailwind
      return 5;
    } else {
      return 0;
    }
  };

  const availableColors = [
    "#F7A23B", "#F75D5F", "#00C7F2", "#FBC62F", "#0FCA7A", "#695CFB", "#2B4270", "#679E7B", "#9F69A3", "#948C4F"
  ]

  const buildGrahicData = (data) => {
    const result = {}
    Object.keys(data).forEach((key) => {
      result[key] = Number(data[key]) * 100
    })
    const fieldsDistributions = Object.entries(result);
    const sortedFields = fieldsDistributions.filter(([, value]) => value)
                                            .sort(([, valueA], [, valueB]) => valueB - valueA);
    const filteredObject = Object.fromEntries(sortedFields);
    const workFields = Object.keys(filteredObject)
    setXValues(workFields)
    setYValues(Object.values(filteredObject))
    const workFieldsCount = workFields.length
    setColors(availableColors.slice(0, workFieldsCount))
  }

  useEffect(() => {
    fetchWorkFieldsDistribution()
      .then((response) => response.json())
      .then((parsedResponse) => {
        buildGrahicData(parsedResponse)
        setloading(false)
      });
  }, []);


  const data = {
    labels: xValues.map((field) =>  i18n.t(`homePage.remunerations.work_field_distribution.legends.${field}`)),
    datasets: [
      {
        data: yValues,
        backgroundColor: colors,
        borderWidth: 0,
        cutout: "70%"
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: getLegendPadding(),
          fullSize: true,
          color: '#2D2D2D'
        },
        onClick: (e) => e.stopPropagation()
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return `${tooltipItem.label}: ${Number(value).toFixed(2)}%`;
          }
        }
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 20,
      },
    },
    animation: {
      onComplete: (animation) => {
        const chartInstance = animation.chart;
        const ctx = chartInstance.ctx;
        ctx.font = '500 12px Inter';
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        chartInstance.data.datasets[0].data.forEach((value, i) => {
          const meta = chartInstance.getDatasetMeta(0);
          const element = meta.data[i];
          if (!element || !element.circumference || Number(value) <= 4) return;
          const { x, y } = element.tooltipPosition();
          ctx.fillStyle = '#000000';
          ctx.fillText(`${Number(value).toFixed(0)}%`, x, y);
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div className="p-0 my-0 w-full lg:h-[380px] xl:h-[400px] 2xl:h-[437px]" id="users-allowances-distribution" >
      <div className="chart-container">
        <div className="border w-full h-full rounded-t h-[92px] bg-white">
          <div className="flex flex-col w-full px-6 pt-6 pb-4 gap-2">
            <p className="font-sans text-xl/6 font-semibold text-left text-black-report w-[90%]" >
              {i18n.t(`homePage.remunerations.work_field_distribution.title`)}
            </p>
            <p className="font-sans text-sm/5 font-normal text-left text-default-gray-2 w-[90%]" >
              {i18n.t(`homePage.remunerations.work_field_distribution.subtitle`)}
            </p>
          </div>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className="border-l border-r border-b rounded-b mt-0 w-auto lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white"
          id="chart-work-field-distribution"
        >
          {
            loading ? 
            <LoadingPage
              customHeight="h-full"
              customWidth="w-full"
              customImageClass={"w-10 h-10"}
              customTextClass={'hidden'}
            /> : (
              <Doughnut data={data} options={options} plugins={[customLegend]} />
            )
          }
        </div>
      </div>
    </div>
  )
}
