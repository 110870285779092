import React, { useState, useContext, useEffect } from 'react';
import i18n from "#translate/i18n";

import Title from '#components/user/profile/Title';
import ActiveAllowances from '#components/user/profile/remunerationTab/ActiveAllowances';
import LatestRewards from '#components/user/profile/remunerationTab/LatestRewards';
import LatestExpenseAssistances from '#components/user/profile/remunerationTab/LatestExpenseAssistances';
import RemovalConfirmation from '#components/user/profile/RemovalConfirmation';
import Remunerations from './remunerationTab/Remunerations';

import remunerationIcon from "#images/profile_drawer/remuneration-icon.svg";
import activeAllowancesIcon from "#images/profile_drawer/active-allowances-icon.svg";
import expenseAssistanceIcon from "#images/expense_assistance_drawer/icon.svg"
import bonusRewardIcon from "#images/bonus_drawer/icon.svg"

import removeBonusReward from '#requests/userProfile/removals/removeBonusReward';
import removeAllowance from '#requests/userProfile/removals/removeAllowance';
import removeExpenseAssistance from '#requests/userProfile/removals/removeExpenseAssistance';

import { UserContext } from "#providers/UserContextProvider"

export default function RemunerationTab({
  userData,
  handleOpenedDrawersFromProfile,
}) {
  const { refreshUserData, setRefreshUserData, objectId, setObjectId, setProfileTabName, setShowMessage } = useContext(UserContext)

  const [openRemovalConfirmation, setOpenRemovalConfirmation] = useState(false)
  const [chosenObjectRemoval, setChosenObjectRemoval] = useState()
  const [rewardType, setRewardType] = useState()

  useEffect(() => {
    setProfileTabName('remuneration')
  }, []);

  const objectRemovals = {
    "BonusReward": {
      removal: () => removeBonusReward({ user: userData, id: objectId }),
      title: 'timelineEvents.bonusRewardCreated.confirmation.title',
      text: 'timelineEvents.bonusRewardCreated.confirmation.text',
      removalSource: 'bonus_reward_removed'
    },
    "ActiveMeal": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'meal' }),
      title: 'timelineEvents.mealCreated.confirmation.title',
      text: 'timelineEvents.mealCreated.confirmation.text',
      removalSource: 'meal_allowance_removed'
    },
    "ActivePsychologicalSupport": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'psychological_support' }),
      title: 'timelineEvents.psychologicalAssistanceCreated.confirmation.title',
      text: 'timelineEvents.psychologicalAssistanceCreated.confirmation.text',
      removalSource: 'psychological_support_removed'
    },
    "ActiveHomeOffice": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'home_office' }),
      title: 'timelineEvents.homeOfficeCreated.confirmation.title',
      text: 'timelineEvents.homeOfficeCreated.confirmation.text',
      removalSource: 'home_office_allowance_removed'
    },
    "ActiveYearEndBonus": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'year_end_bonus' }),
      title: 'timelineEvents.yearEndBonusCreated.confirmation.title',
      text: 'timelineEvents.yearEndBonusCreated.confirmation.text',
      removalSource: 'year_end_bonus_removed'
    },
    "ActiveHealthcares": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'healthcare' }),
      title: 'timelineEvents.healthcareCreated.confirmation.title',
      text: 'timelineEvents.healthcareCreated.confirmation.text',
      removalSource: 'healthcare_removed'
    },
    "ActiveParentalLeave": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'parental_leave' }),
      title: 'timelineEvents.parentalLeaveCreated.confirmation.title',
      text: 'timelineEvents.parentalLeaveCreated.confirmation.text',
      removalSource: 'parental_leave_removed'
    },
    "ActiveVacation": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'vacation' }),
      title: 'timelineEvents.vacationCreated.confirmation.title',
      text: 'timelineEvents.vacationCreated.confirmation.text',
      removalSource: 'vacation_removed'
    },
    "ExpenseAssistance": {
      removal: () => removeExpenseAssistance({ user: userData, id: objectId }),
      title: 'timelineEvents.expenseAssistanceCreated.confirmation.title',
      text: 'timelineEvents.expenseAssistanceCreated.confirmation.text',
      removalSource: 'expense_assistance_removed'
    },
  }

  const removeObject = (type) => {
    objectRemovals[type]?.removal?.()
      .then(() => {
        setOpenRemovalConfirmation(false)
        setRefreshUserData(!refreshUserData)
        const removalSuccessMessageSource = objectRemovals[chosenObjectRemoval]?.removalSource
        const newMessage = {
          success: true,
          source: removalSuccessMessageSource,
        }
        if (removalSuccessMessageSource === 'bonus_reward_removed') { 
          newMessage['additionalData'] = i18n.t(`drawerResponses.success.bonus_reward_removed.rewardType.${rewardType}`)
        }
        setShowMessage(messages => [newMessage, ...messages])
      })
  }

  const cancelFunction = () => {
    setOpenRemovalConfirmation(false)
    setObjectId(null)
    setRewardType(null)
    setChosenObjectRemoval(null)
  }

  return (
    <>
      <div>
        <Title
          title={i18n.t('userProfile.remuneration_tab.remuneration.title')}
          icon={remunerationIcon}
          customImageStyle='h-5 w-5'
        />
        <div className='w-full gap-5 grid' >
          <Remunerations userData={userData} />
        </div>
      </div>
      <div>
        <Title
          title={i18n.t('userProfile.remuneration_tab.active_allowances.title')}
          icon={activeAllowancesIcon}
          customImageStyle='h-5 w-5'
        />
        <div className='w-full gap-5 grid' >
          <ActiveAllowances
            userData={userData}
            setOpenRemovalConfirmation={setOpenRemovalConfirmation}
            setChosenObjectRemoval={setChosenObjectRemoval}
            handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
          />
        </div>
      </div>
      <div>
        <Title
          title={i18n.t('userProfile.remuneration_tab.latest_rewards.title')}
          icon={bonusRewardIcon}
          customImageStyle='h-6 w-6 -ml-1'
        />
        <div className='w-full gap-5 grid' >
          <LatestRewards
            userData={userData}
            refreshUserData={refreshUserData}
            setOpenRemovalConfirmation={setOpenRemovalConfirmation}
            setRewardType={setRewardType}
            setChosenObjectRemoval={setChosenObjectRemoval}
            handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
          />
        </div>
      </div>
      <div>
        <Title
          title={i18n.t('userProfile.remuneration_tab.expense_assistances.title')}
          icon={expenseAssistanceIcon}
          customImageStyle='h-6 w-6'
        />
        <div className='w-full gap-5 grid' >
          <LatestExpenseAssistances
            userData={userData}
            refreshUserData={refreshUserData}
            setOpenRemovalConfirmation={setOpenRemovalConfirmation}
            setChosenObjectRemoval={setChosenObjectRemoval}
            handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
          />
        </div>
      </div>

      {openRemovalConfirmation &&
        <RemovalConfirmation
          cancelFunction={cancelFunction}
          removalFunction={() => removeObject(chosenObjectRemoval)}
          objectId={objectId}
          userData={userData}
          i18nTitle={objectRemovals[chosenObjectRemoval]?.title}
          i18nText={objectRemovals[chosenObjectRemoval]?.text}
        />
      }
    </>
  );
}
