import React, { useEffect, useState } from 'react';
import i18n from "#translate/i18n";

import fetchExpenseAssistances from '#requests/userProfile/fetchExpenseAssistances';

import ExpenseAssistance from '#components/user/profile/remunerationTab/ExpenseAssistance'

export default function LatestExpenseAssistances({
  userData,
  handleOpenedDrawersFromProfile,
  refreshUserData,
  setOpenRemovalConfirmation,
  setChosenObjectRemoval
}) {
  const [userExpenseAssistances, setUserExpenseAssistances] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchExpenseAssistances({ userId: userData.id, limit: 8 })
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUserExpenseAssistances(parsedResponse)
        setloading(false)
      });
  }, [userData, refreshUserData]);

  return (
    <>
      {
        !loading && !userExpenseAssistances?.length ?
          <div className='text-info-gray p-6 text-center'>
              {i18n.t('userProfile.remuneration_tab.expense_assistances.no_expense_assistance')}
          </div>
        :
        <section id='user-latest-expenses' >
          { userExpenseAssistances && (
            <div className="gap-4 grid grid-cols-2 py-5 px-0 my-0 mx-5" >
              { userExpenseAssistances.map((expense_assistance, index) => (
                <div key={index}>
                  <ExpenseAssistance
                    expenseAssistanceData={expense_assistance}
                    setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                    setChosenObjectRemoval={setChosenObjectRemoval}
                    handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                    userData={userData}
                  />
                </div>
              ))}
          </div>
          )}
        </section>
      }
    </>
  )
}
