import React, { useState, useEffect } from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next";
import { useFeatureFlags } from '#providers/FeatureFlagsProvider';
import moment from 'moment';
import 'moment/locale/pt-br';

import userPresentedName from '#services/userPresentedName';

import DismissalIcon from '#images/user_dismissal_drawer/document.svg';
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg";

export default function HealthcareUpdated({
  eventData,
  userData,
  lastUpdates,
  setObjectId,
  showDetails,
  setShowDetails,
  openEditionDrawer,
  lastCreation
}) {
  const { featureFlags } = useFeatureFlags();
  const isEditDismissalEnabled = featureFlags.edit_dismissal;

  const data = eventData?.data || {}
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)
  const adminName = data.responsible?.full_name

  const exitDate = moment(data.exit_date, 'YYYY-MM-DD');
  const exitMeetingDate = moment(data.exit_meeting_date, 'YYYY-MM-DD');
  const invoiceInstallments = data.invoice_installments || []
  const terminationBalance = Number(data.termination_balance)

  const invoiceInstallmentsNumber = invoiceInstallments.length
  const extendedAllowances = data.extended_allowances || []

  let extentendedAllowancesNames = new Set();
  let filteredNames = extendedAllowances.filter(item => {
    if (extentendedAllowancesNames.has(item.allowance_type)) {
      return false;
    } else {
      extentendedAllowancesNames.add(item.allowance_type);
      return true;
    }
  });

  const eventDateTime = eventData?.date || ''
  const isTheLastUpdate = lastUpdates.some((update) => update.id === data.id && update.lastUpdatedOn === eventDateTime) && lastCreation('DismissalCreated').data.id === data.id

  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <>
      <div className="relative flex-1 my-5 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit bg-light-support-frozen'>
            <img
              src={DismissalIcon}
              alt="event icon"
              className='w-12 h-12 px-1.5 py-2'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
            <p className="font-bold inline-block">
              {`${i18n.t("timelineEvents.userDismissalUpdated.title")}`}
            </p>
            <p className="font-normal">
              <Trans
                i18nKey={`${i18n.t("timelineEvents.userDismissalUpdated.description")}`}
                values={{
                  admin: adminName, person: userName
                }}
              />
            </p>
          </div>
          <button
            id='dismissal-updated-details-button'
            className='ml-6 mb-3 p-2'
            onClick={() => { setClick(!click), setShowDetails(null) }}
          >
            <img
              className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
              src={ArrowDown}
              alt={i18n.t("dropdown_arrow_img")}
            />
          </button>
        </div>
        {click && (
          <>
            <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='dismissal-updated-details-panel'>
              <p id='dismissal-updated-reason'>
                <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.dismissal_reason')}</span>
                {i18n.t(`timelineEvents.userDismissalCreated.reason.${data.reason}`)} - {data.notes_reason}
              </p>
              <p id='dismissal-updated-exit-date'>
                <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.exit_date')}</span>
                {exitDate.format('DD/MM/YYYY')}
              </p>
              <p id='dismissal-updated-exit-meeting-date'>
                <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.exit_meeting_date')}</span>
                {exitMeetingDate.format('DD/MM/YYYY')}
              </p>
              {terminationBalance > 0 && (
                <p id='dismissal-updated-termination-balance'>
                  <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.termination_balance')}</span>
                  {Number(terminationBalance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </p>
              )}
              {invoiceInstallmentsNumber > 0 && (
                <p id='dismissal-updated-invoice-installments-number'>
                  <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.invoice_installments_number_title')}</span>
                  {i18n.t('timelineEvents.userDismissalCreated.invoice_installments_number', { count: invoiceInstallmentsNumber })}
                </p>
              )}

              {filteredNames?.map((allowance, index) => (
                <p id={`dismissal-updated-extended-allowances-${index + 1}`} key={allowance.id}>
                  <span className="font-bold">{i18n.t('timelineEvents.userDismissalCreated.extended_allowance.title', { count: index + 1 })}</span>
                  {i18n.t(`timelineEvents.userDismissalCreated.extended_allowance.name.${allowance.allowance_type.replace("Allowances::", "")}`)}
                </p>
              ))}
            </div>

            {isEditDismissalEnabled && isTheLastUpdate && (
              <div className="flex space-x-4 justify-end mt-5" id="dismissal-updated-manage-buttons">
                <div className="flex space-x-4 group">
                  <button
                    className='justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5 text-rebase-blue border-rebase-blue bg-white'
                    id='dismissal-updated-edit-button'
                    onClick={() => { openEditionDrawer(), setObjectId(data.id) }}
                  >
                    <img src={EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                    {i18n.t('timelineEvents.userDismissalCreated.editDismissal')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
