import React, { useContext } from "react"
import i18n from "#translate/i18n";
import moment from "moment"

import RoundPicture from "#components/user/RoundPicture"
import MessageText from "#components/navigation/MessageText";
import ImgProfile from "#images/profile_picture.svg";
import DivisionLine from '#components/formComponents/DivisionLine'
import { TooltipMessage } from "#components";
import capitalizeFirstLetter from "#services/capitalizeFirstLetter"
import SecondaryButton from "#components/buttons/small/SecondaryButton"

import updateUserMessageStatus from "#requests/users/updateUserMessageStatus";

import { UserContext } from "#providers/UserContextProvider"

export default function Message({ messageData, alreadyRead, setRefreshMessages }) {
  const data = messageData['params']

  const { setObjectId, handleDrawersOpen, setMenuOrigin } = useContext(UserContext)

  const splitDate = (dateString) => {
    const date = moment(dateString).locale("pt-br");
    const day = date.date();
    const monthNumber = date.month() + 1; // Gets the month (0-11, so add 1)
    const year = date.year();
    const monthName = i18n.t(`notification.monthNames.${monthNumber}`)
    return { day: day, month: monthName, year: year }
  }

  const formattedDate = moment(messageData["created_at"]).locale("pt-br").format('D [de] MMMM, h:mm A');
  const [day, monthAndTime] = formattedDate.replace(/de\s/, '||separator||').split('||separator||');
  const capitalizedMonth = capitalizeFirstLetter(monthAndTime)
  const createdAtDate = `${day}de ${capitalizedMonth}`

  const messagesTypeData = {
    'Notifications::ExpenseAssistanceExpiring': () => {
      const expenseAssistance = data["expense_assistance"]
      const { day, month, year } = splitDate(expenseAssistance["payment_end_date"])
      return {
        disableButton: () => moment(expenseAssistance["payment_end_date"]) < moment(),
        drawer: "expenseAssistance",
        id: expenseAssistance.id,
        user: data["user"],
        i18nkey: "expenseAssistanceExpiring",
        i18nText: i18n.t('notification.expenseAssistanceExpiring.text'),
        values: { reason: expenseAssistance["reason"], name: data["user"]["full_name"], day: day, month: month, year: year }
      }
    }
  }

  const messageType = messageData["notification_subclass"]
  const messageDataKeys = messagesTypeData[messageType]?.() || {}
  const messageI18nString = messageDataKeys["i18nText"] || ""
  const messageValues = messageDataKeys["values"] || {}

  const handleReadClick = () => {
    updateUserMessageStatus({messageId: messageData["id"]}).then(() => setRefreshMessages(true))
  }

  const handleEditClick = () => {
    const id = messageDataKeys["id"]
    const user = messageDataKeys["user"]
    const drawer = messageDataKeys["drawer"]
    setMenuOrigin['people'](true)
    setObjectId(id)
    handleDrawersOpen(user, drawer)
  }

  return (
    <>
      <DivisionLine customStyle='m-0 p-0' />
      <div className={`flex flex-row font-sans ${alreadyRead ? 'bg-white' : 'bg-light-gray'}`}>
        <div className="flex items-center w-12 h-12 m-5 bg-white rounded-full shadow-round">
          <RoundPicture
            divStyle="w-11 h-11"
            imgStyle='object-cover'
            alt="profile"
            id={`user-id-test`}
            src={messageData["face_picture"]?.["url"] || ImgProfile}
          />
        </div>
        <div className="mt-5">
          <p className="font-sans font-semibold text-sm">{i18n.t(`notification.messageTitle.${messageDataKeys["i18nkey"]}`)}</p>
          <div className="flex flex-row mt-1">
            <div className="font-normal font-sans text-sm/6">
              <MessageText
                i18nText={i18n.t(messageI18nString)}
                values={messageValues}
              />
            </div>

            <TooltipMessage
              arrowClass="w-2.5 h-2.5 bg-white arrow-position before:bg-white before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 shadow-lg"
              tooltipClass="w-max py-1 px-3 text-black leading-6 rounded-lg bg-white tooltip-position font-normal text-xs font-sans not-italic z-[1000] shadow-lg font-semibold"
              placement='left'
              buttonId={alreadyRead ? 'button-from-read' : 'button-from-unread'}
              message={alreadyRead ? i18n.t('notification.markAsUnread') : i18n.t('notification.markAsRead')}
              offset={[0, 10]}
              customOnClick={handleReadClick}
              imageComponent={
                <div
                  className={`mr-6 mt-8 w-3 h-3 rounded-full focus:outline-none ${alreadyRead ? 'bg-placeholder-gray' : 'bg-rebase-blue'}`}
                />
              }
            />
          </div>

          <SecondaryButton
            defaultText={i18n.t(`notification.editButton.${messageDataKeys["i18nkey"]}`)}
            buttonId={"edit-button"}
            buttonClass={"justify-center flex items-center px-2 py-2 mt-2 mb-2 w-auto ml-0"}
            type={'button'}
            onClick={handleEditClick}
            imagePresent={false}
            disabled={messageDataKeys.disableButton()}
          />
          <p className="font-sans font-medium text-xs text-slate-400 mb-5">{createdAtDate}</p>
        </div>
      </div>
    </>
  )
}