/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import i18n from "#translate/i18n"
import toast, { Toaster } from "react-hot-toast"

import { People as PeopleListing } from '#components/listings/People'
import Search from '#components/listings/people/Search'
import FilterButton from '#components/listings/people/FilterButton'
import Actions from '#components/listings/people/Actions'
import NewPerson from '#components/listings/people/NewPerson'
import { Welcome, BreadCrumbs, MessageInviteSuccess } from '#components'
import InviteUser from '#components/listings/people/modals/InviteUser'
import CustomizedExportSheet from '#components/listings/people/data/drawers/CustomizedExportSheet'

import { UserContext } from "#providers/UserContextProvider"
import { UserFormsContext } from "#providers/UserFormsProvider"

import UsersRequest from "#requests/UsersRequest"
import userSessionRequest from "#requests/userSessionRequest"

export default function People() {
  const {
    refreshUserData,
    setRefreshUserData,
    people,
    setPeople,
    selectedPerson,
    setSelectedPerson,
    notifications
  } = useContext(UserContext)
  const { openUserEditForms, openNewUserForm, setOpenNewUserForm } = useContext(UserFormsContext)

  const [authenticatedUser, setAuthenticatedUser] = useState({})
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState([null, null])
  const [statusFilter, setStatusFilter] = useState([])
  const [projectFilter, setProjectFilter] = useState([])
  const [workFieldFilter, setWorkFieldFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  const [unselectedAll, setUnselectedAll] = useState(false);

  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showMessageInviteSuccess, setShowMessageInviteSuccess] = useState(false)
  const [signalAbortRequest, setSignalAbortRequest] = useState(null)

  const [openExportDataDrawer, setOpenExportDataDrawer] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      return await userSessionRequest()
    }

    getAuthenticatedUser().then(data => {
      setAuthenticatedUser(data);
      setRefreshUserData(true);
    })
  }, [])


  useEffect(() => {
    setLoading(true);
    setSelectedUsers([])
    setRefreshUserData(true)
  }, [search, showInviteModal, dateRange, statusFilter, projectFilter, workFieldFilter])

  useEffect(() => {
    toast(notifications)
  }, [notifications])

  useEffect(() => {
    if(authenticatedUser?.role === 'admin' && refreshUserData) {
      setSelectAll(false)
      setUnselectedAll(true)
      const searchPeople = async () => {
        let filters = {};

        if (search || dateRange[0] || dateRange[1] || statusFilter.length || projectFilter.length || workFieldFilter.length) {
          filters = {
            name_or_email: search,
            initial_date: dateRange[0],
            final_date: dateRange[1],
            status: statusFilter,
            projects: projectFilter,
            work_fields: workFieldFilter
          }
        }

        try {
          const users = await UsersRequest({ filters: filters, signal: signalAbortRequest });

          setPeople(users);
          if (selectedPerson) {
            const user = users.find((user) => (user.id && user.id === selectedPerson.id) || user.corporate_email === selectedPerson.corporate_email);
            if (user) setSelectedPerson(user);
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error('Fetch error:', error);
          }
        } finally {
          setRefreshUserData(false);
          setLoading(false);
          setUnselectedAll(false)
        }
      };

      searchPeople();
    }
  }, [refreshUserData, authenticatedUser]);

  const tablePeopleOrWelcome = () => {
    return (
      <>
        {authenticatedUser?.role === 'admin' ? (
          <div
            className={`ml-5 overflow-hidden ${showInviteModal ? 'fixed bottom-0 top-0 right-2.5 left-28' : ''}`}
            id='people-table-div'
          >
            <Toaster />
            <section id="people-header" className={`${openUserEditForms || openNewUserForm ? 'hidden' : ''}`}>
              <BreadCrumbs name={i18n.t("users.title")} />
              <div className='relative flex justify-between items-center'>
                <h1 className='flex font-sans font-bold text-3xl text-slate-800 grow'>Pessoas</h1>
                <div className="flex mr-5">
                  <Search
                    setSearch={setSearch}
                    setSignalAbortRequest={setSignalAbortRequest}
                    loading={loading}
                    setLoading={setLoading}
                  />
                  <FilterButton
                    setDateRange={setDateRange}
                    setStatusFilter={setStatusFilter}
                    setProjectFilter={setProjectFilter}
                    setWorkFieldFilter={setWorkFieldFilter}
                  />
                  <div className='w-0.5 h-11 bg-gray-400/50 flex-none mx-3' />
                  <Actions setOpenExportDataDrawer={setOpenExportDataDrawer} selectedUsers={selectedUsers}/>
                  <NewPerson setShowInviteModal={setShowInviteModal} setOpenNewUserForm={setOpenNewUserForm} />
                </div>
              </div>
            </section>
            <section
              id="people-main"
              className={`flex-shrink-0 ${openUserEditForms || openNewUserForm || people.length === 0 ? 'border-none' : 'mt-8 border-[1px] border-solid border-border-gray h-[calc(100vh-13.5rem)] mr-5 overflow-hidden'}`}
            >
              <PeopleListing
                people={people}
                openNewUserForm={openNewUserForm}
                setSelectedUsers={setSelectedUsers}
                selectedUsers={selectedUsers}
                selectAll={selectAll}
                unselectedAll={unselectedAll}
                setSelectAll={setSelectAll}
                setUnselectedAll={setUnselectedAll}
              />
            </section>
          </div>
        ) : authenticatedUser?.role === 'user' && (
          <Welcome user={authenticatedUser} />
        )}
      </>
    )
  }

  return (
    <>
      {tablePeopleOrWelcome()}
      {
        showInviteModal && <InviteUser setShowMessageInviteSuccess={setShowMessageInviteSuccess} setShowInviteModal={setShowInviteModal} showInviteModal={showInviteModal} />
      }
      {
        showMessageInviteSuccess && <MessageInviteSuccess />
      }
      {
        openExportDataDrawer && <CustomizedExportSheet
          setOpenExportDataDrawer={setOpenExportDataDrawer}
          selectedUsers={selectedUsers}
        />
      }
    </>
  )
}
