import React from "react";
import i18n from "#translate/i18n";
import EmailIcon from '#images/profile_drawer/email-icon.svg';
import MobileIcon from '#images/profile_drawer/mobile-icon.svg';
import LandlineIcon from '#images/profile_drawer/landline-icon.svg';
import ClientIcon from '#images/profile_drawer/client-icon.svg';
import CompanyIcon from '#images/profile_drawer/company-icon.svg';
import WorkFieldIcon from '#images/profile_drawer/work-field-icon.svg';
import CalendarIcon from '#images/profile_drawer/calendar-icon.svg';
import TimeInCompany from "#components/user/profile/TimeInCompany";
import userPresentedName from '#services/userPresentedName';
import formatPhoneNumber from '#utils/formatPhoneNumber';

export default function ProfileInfo({ userData }) {
  const { contacts, company_history, number_of_months_in_the_company } = userData
    ? userData
    : {};
  return (
    <div
      className="font-[Inter] font-normal px-5 pb-px pt-2.5 flex-auto"
      id='user_information'
    >
      <div className='font-semibold text-2xl text-midnight' id="user-full-name">
        {userPresentedName(userData)}
        <span className='font-normal'>{userData.pronouns
          && ` (${i18n.t(`pronouns.${userData.pronouns}`)})`
        }
        </span>
      </div>
      <div className="text-info-gray text-sm" id="user-info">
        {company_history?.work_field
          && <section className="inline-block pt-3.5 pr-[42px]" id="user-work-field">
            <img
              id='work_field_icon'
              className='inline pr-2.5'
              src={WorkFieldIcon}
              alt={i18n.t('userProfile.work_field_icon')}
            />
            {i18n.t(`work_field.${company_history.work_field}`)}
          </section>
        }
        {company_history?.company
          && <section className="inline-block pt-3.5 pr-[42px]" id="user-company">
            <img
              id='company_icon'
              className='inline pr-2.5'
              src={CompanyIcon}
              alt={i18n.t('userProfile.company_icon')}
            />
            {i18n.t(`company.${company_history.company}`)}
          </section>
        }
        {number_of_months_in_the_company >= 0
          && <section className="inline-block pr-[42px] pt-3.5" id="user-months-in-the-company">
            <img
              id='calendar_icon'
              className='inline pr-2.5'
              src={CalendarIcon}
              alt={i18n.t('userProfile.calendar_icon')}
            />
            <TimeInCompany
              months={number_of_months_in_the_company}
            />
          </section>
        }
        {userData.current_client_name
          && <section className="inline-block pr-[42px] pt-3.5" id="user-client">
            <img
              id='client_icon'
              className='inline pr-2.5'
              src={ClientIcon}
              alt={i18n.t('userProfile.client_icon')}
            />
            {userData.current_client_name}
          </section>
        }
        {contacts?.[0]?.phone_number && contacts[0].phone_number !== ' '
          && <section className="inline-block pr-[42px] pt-3.5" id="user-phone-number">
            <img
              id='mobile_icon'
              className='inline pr-2.5'
              src={MobileIcon}
              alt={i18n.t('userProfile.mobile_icon')}
            />
            {formatPhoneNumber(contacts[0].phone_number)}
          </section>
        }
        {contacts?.[0]?.landline && contacts[0].landline !== ' '
          && <section className="inline-block pr-[42px] pt-3.5" id="user-landline">
            <img
              id='landline_icon'
              className='inline pr-2.5'
              src={LandlineIcon}
              alt={i18n.t('userProfile.landline_icon')}
            />
            {formatPhoneNumber(contacts[0].landline)}
          </section>
        }
        {contacts?.[0]?.corporate_email
          && <section className="inline-block pt-3.5" id="user-corporat-email">
            <img
              id='email_icon'
              className='inline pr-2.5 profile-icon-filter'
              src={EmailIcon}
              alt={i18n.t('userProfile.email_icon')}
            />
            {contacts[0].corporate_email}
          </section>
        }
      </div>
    </div>
  );
}
