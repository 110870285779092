import React, { useState } from "react";
import i18n from "#translate/i18n";
import { useLocation } from "react-router-dom";
import Field from "./Field";

const SelectInput = ({
  model,
  name,
  title,
  requiredField,
  value,
  options,
  subtitle,
  modalComponent,
  index,
  optionsI18nPrefix,
  tooltip,
  disabled,
  inviteFieldStyle,
  className,
  fieldInfo,
  shouldHide,
  orderAlphabetically,
  showDefaultPlaceholder=true,
  showDefaultFirstOption=true,
  openReferenceTemplate,
  hasReferenceTemplate,
  ...props
}) => {
  const location = useLocation();
  const defaultSelection = location.pathname === "/onboarding" ? "" : value;

  const [currentOption] = useState(defaultSelection);

  const hasIndex = typeof index === "number";
  const namePrefix = hasIndex ? `${index}_` : "";
  const inputNamePrefix = hasIndex ? `${index}.` : "";
  const modelPrefix = model ? `${model}_` : "";
  const selectId = `${modelPrefix}${namePrefix}${name}`;
  const optionPrefix = optionsI18nPrefix ? `${optionsI18nPrefix}.` : "";
  const component = shouldHide ? 'p' : 'select'

  const buildOptionValues = (option) => {
    if (!option && option !== false) {
      return { id: "", name: "", value: "" };
    }

    if (typeof option !== "object") {
      return {
        id: option,
        name: i18n.t(`${optionPrefix}${name}.${option}`),
        value: option,
      };
    }

    const optionId = option?.id;
    const optionName = option?.name;
    const buildName = props.applyi18n ? i18n.t(`${optionPrefix}${name}.${optionName}`) : `${optionPrefix}${optionName}`
    const optionValue = option?.value || option?.id;
    const optionDisabled = option?.disabled || false

    return {
      id: optionId,
      name: buildName,
      value: optionValue,
      disabled: optionDisabled,
    };
  };

  const ReferenceTemplateAction = () => {
    return (
      <span className="pl-1">
        (
        <button
          className="text-rebase-blue underline underline-offset-2 font-normal cursor-pointer text-sm"
          onClick={openReferenceTemplate}
          id={`open_${name}_reference_template_button`}
        >
          {i18n.t("reference_modal_title")}
        </button>
        )
      </span>
    );
  };

  const unorderedOptionsArray = options.map((option) => buildOptionValues(option))
  const orderedOptionsArray = orderAlphabetically && unorderedOptionsArray.sort((a,b) => a.name.localeCompare(b.name))
  const optionsArray = orderedOptionsArray || unorderedOptionsArray

  return (
    <div className="flex flex-col justify-end" id={selectId}>
      <Field
        {...props}
        label={
          <div className={`${shouldHide ? "w-0 h-0" : "" }`}>
            { shouldHide ? <></> :
              <>
                {title}

                { requiredField && (
                  <span 
                    className="pl-1 text-red-500"
                    id="required-field"
                  >
                    *
                  </span>
                )}

                { fieldInfo }

                {subtitle && (
                  <span className="pl-1">
                    (<span className="font-normal">{subtitle}</span>)
                  </span>
                )}

                {tooltip}

                {modalComponent}

                { hasReferenceTemplate && <ReferenceTemplateAction />}
              </>
            }
          </div>
        }
        inviteFieldStyle={inviteFieldStyle}
        model={model}
        id={`${selectId}_input`}
        name={`${inputNamePrefix}${name}`}
        component={component}
        defaultValue={currentOption}
        disabled={disabled}
        className={`${inviteFieldStyle ? `${inviteFieldStyle}` : "py-2 px-3 mt-2 text-md block rounded w-[102%] h-11 bg-white border-border-gray placeholder-placeholder-gray focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"} ${shouldHide ? 'w-0 h-0 border-none focus:border-none appearance-none' : ''} ${className}`}
      >
        {!shouldHide &&
          <>
            {
              showDefaultPlaceholder && showDefaultFirstOption &&
              <option value="">
                {i18n.t("placeholders.select_option")}
              </option>
            }
            {showDefaultPlaceholder && !showDefaultFirstOption &&
              <option value="" hidden>
                {i18n.t("placeholders.select_option")}
              </option>
            }
            {optionsArray.map((option) => {
              return (
                <option key={option.id} value={option.value} style={props.liststyle} disabled={option.disabled}>
                  {option.name}
                </option>
              );
            })}
          </>
        }
      </Field>
    </div>
  );
};

SelectInput.defaultProps = {
  className: "",
  disabled: false,
  index: "",
};

export default SelectInput;