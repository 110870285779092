import axios from "axios";
import toFormData from "#requests/helpers/toFormData"

export default function updateUserRequest(data, userId) {
  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "PUT",
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(data, "user"),
    };
  };

  const options = requestOptions()
  const url = `/api/v1/users/${userId}`

  return axios({ url, ...options })
}