/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { Popover, Transition } from "@headlessui/react"
import i18n from "#translate/i18n";
// import SearchLens from '#images/search-lens.svg'
// import BarTop from '#images/bar-top.svg';
import Bell from '#images/bell-top.svg';
import Hand from '#images/hand-top.svg';
import ProfileAvatar from '#images/profile-avatar.svg'
import HeaderMenu from "#components/navigation/HeaderMenu";
import LoadingPage from "#components/navigation/LoadingPage";
import SuccessMessage from "#components/messages/SuccessMessage"
import RoundPicture from "#components/user/RoundPicture";
import DivisionLine from '#components/formComponents/DivisionLine'
import RenderNotifications from "#components/navigation/RenderNotifications";

import userRequest from "#requests/UserRequest";
import userSessionRequest from "#requests/userSessionRequest";
import fetchUserMessages from "#requests/users/fetchUserMessages";
import leaveSchedules from "#requests/users/leaveSchedules"
import userPresentedName from '#services/userPresentedName';

import ProfileDrawer from '#components/user/profile/ProfileDrawer'
import FinancialAdditions from "../finances/drawers/FinancialAdditions"

import NewUser from "#components/NewUser";
import EditUser from "#components/EditUser"

import { UserContext } from "#providers/UserContextProvider"
import { UserFormsContext } from "#providers/UserFormsProvider"
import { useFeatureFlags } from '#providers/FeatureFlagsProvider'
import getCable from '../../cable.js';

export default function Header() {
  const [authenticatedUserData, setAuthenticatedUserData] = useState("");
  const [sessionData, setSesisonData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [readMessagesData, setReadMessages] = useState([])
  const [unreadMessagesData, setUnreadMessages] = useState([])
  const [refreshMessages, setRefreshMessages] = useState(false);
  const { featureFlags } = useFeatureFlags();
  const isNotificationsEnabled = featureFlags.handle_notifications;

  const {
    setRefreshUserData,
    selectedPerson,
    openedDrawers,
    handleDrawersOpen,
    menuOrigin,
    setProfileTabName,
    refreshUserData,
    setUsersLeaveSchedules,
    showMessage,
    setShowMessage
  } = useContext(UserContext)
  const {
    openUserEditForms,
    setOpenUserEditForms,
    userDataEditForms,
    setUserDataEditForms,
    openNewUserForm,
    setOpenNewUserForm,
  } = useContext(UserFormsContext)

  useEffect(() => {
    async function fetchSessionData() {
      const userSession = await userSessionRequest();
      setSesisonData(userSession)
      if(!userSession) { return }

      const [ userData, messages ] = await Promise.all([userRequest(userSession.id), fetchUserMessages(),])
      setAuthenticatedUserData(userData);
      const messagesData = messages || []
      setReadMessages(messagesData["read"]);
      setUnreadMessages(messagesData["unread"]);
      setIsLoading(false);

      const cable = getCable();
      const channel = cable.subscriptions.create(
        { channel: 'NotificationChannel' },
        {
          received(data) {
            setUnreadMessages(prev => [data.message, ...prev]);
          }
        }
      );

      return () => {
        channel.unsubscribe();
      };
    }
    fetchSessionData()
  }, []);

  useEffect(() => {
    if(!refreshMessages && !refreshUserData) { return }
    fetchUserMessages().then(parsedBody => {
      const messagesData = parsedBody || []
      setReadMessages(messagesData["read"]);
      setUnreadMessages(messagesData["unread"]);
      setRefreshMessages(false)
      setRefreshUserData(false)
    })
  }, [refreshMessages, refreshUserData]);

  useEffect(() => {
    if(refreshUserData && sessionData?.role === 'admin') {
      (async () => {
        const leavesSchedule = await leaveSchedules();
        setUsersLeaveSchedules(leavesSchedule)
      })();
    }
  }, [refreshUserData, sessionData]);

  if (isLoading) {
    return <div className="loading"><LoadingPage /></div>;
  }

  return (
    <>
      <div className={`flex h-16 justify-end bg-white relative ${openUserEditForms || openNewUserForm ? "z-[2000]" : ""}`}>
        <div className="flex items-center mr-[30px]">
          {/* <img className="w-5 h-5" src={SearchLens} alt="search icon" />
          <img className="h-[42px] ml-5" src={BarTop} alt="bar icon" /> */}
          {isNotificationsEnabled && 
            <Popover className="group relative">
              <Popover.Button className="outline-none" id="messages_button">
                <div className="flex">
                  <img className="w-[16.65px] h-[22.65px] mt-1" src={Bell} alt="bell icon" />
                  { unreadMessagesData.length ? (
                    <div className="w-4 h-4 bg-support-positive text-white text-xs rounded-full flex items-center justify-center -ml-2">
                      {unreadMessagesData.length}
                    </div>
                  ) : <div/>}
                </div>

              </Popover.Button>
                <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0">
                  <div className="rounded-lg shadow-lg bg-white mt-1.5 w-[400px] border border-gray">
                    <p className="text-black-report text-xl font-semibold p-5">{i18n.t('notification.title')}</p>

                    <DivisionLine customStyle='m-0 p-0'/>
                    <RenderNotifications
                      readMessagesData={readMessagesData}
                      unreadMessagesData={unreadMessagesData}
                      setRefreshMessages={setRefreshMessages}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          }

          <img className="w-4 h-7 ml-[20.67px] mr-2" src={Hand} alt="hand icon" />
          <div className="flex items-center font-sans text-base leading-7 tracking-normal text-left">
            <p className="font-bold ">{userPresentedName(authenticatedUserData)}</p>
            <div className="flex items-center w-12 h-12 ml-2 bg-white rounded-full shadow-round font-bold ">
              <RoundPicture
                divStyle="w-11 h-11 m-auto"
                imgStyle='object-cover'
                alt="profile"
                id={`user-id-${authenticatedUserData.id}`}
                src={authenticatedUserData.face_picture?.url ? authenticatedUserData.face_picture.url : ProfileAvatar}
              />
            </div>
            <HeaderMenu
              userData={authenticatedUserData}
            />
          </div>
        </div>
      </div>
      {sessionData?.role === 'admin' && (menuOrigin['people'] || menuOrigin['profile']) &&
        <FinancialAdditions />
      }
      {selectedPerson && openedDrawers['profile'] && (
        <ProfileDrawer
          authenticatedUser={sessionData}
          user={selectedPerson || userDataEditForms}
        />
      )}
      {openUserEditForms && userDataEditForms && (
        <EditUser
          user={userDataEditForms}
          closeDrawers={() => {
            setOpenUserEditForms(false)
            setUserDataEditForms(null)
          }}
          openDrawers={(tabName) => {
            if (menuOrigin['profile']) {
              handleDrawersOpen(userDataEditForms, 'profile')
              if (tabName) { setProfileTabName(tabName) }
              setRefreshUserData(true)
              setUserDataEditForms(null)
            }
          }}
          setShowMessage={setShowMessage}
          showMessage={showMessage}
        />
      )}
      {openNewUserForm && (
        <NewUser
          setOpenNewUserForm={setOpenNewUserForm}
          setShowMessage={setShowMessage}
          openDrawers={() => setRefreshUserData(true)}
        />
      )}
      {
        showMessage.length > 0 &&
        <div className="fixed right-10 bottom-8 z-[2000]">

          {showMessage.map((newMessage, index) => (
            <SuccessMessage key={index}
              setShowMessage={setShowMessage}
              newMessage={newMessage}
            />
          ))}
        </div>
      }
    </>
  )
}
