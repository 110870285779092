import React, { useState } from "react";
import { usePopper } from "react-popper";
import Info from "#images/info.svg";

const TooltipMessage = ({
  message,
  imageClass,
  tooltipClass,
  arrowClass,
  placement,
  offset,
  fallbackPlacements,
  buttonId,
  imageComponent,
  customPopperStyle,
  customOnClick,
  showMessage=true,
}) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "right",
    modifiers: [
      {
        name: "arrow",
        enabled: true,
        options: { element: arrowElement },
        phase: "main"
      },
      { name: "flip", options: { fallbackPlacements: fallbackPlacements || ["top"] } },
      { name: "offset", options: { offset: offset || [0, 10] } },
    ],
  });
  const handleClick = () => {
    setOpen((changeOpen) => !changeOpen);
  };

  let currentArrowClass = arrowClass
  if(attributes.popper && attributes.popper['data-popper-placement'] === 'bottom') {
    currentArrowClass = `${arrowClass} -top-[5px] -ml-1`
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {handleClick(), customOnClick?.()}}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        id={buttonId}
      >
        {
          imageComponent ? (<div ref={setReferenceElement} >{imageComponent}</div>) : (
            <img
              ref={setReferenceElement}
              className={imageClass}
              src={Info}
              alt="info"
            />
          )
        }
      </button>

      {open && showMessage && (
        <div
          role="tooltip"
          className={tooltipClass}
          ref={setPopperElement}
          style={{...styles.popper, ...customPopperStyle}}
          {...attributes.popper}
        >
          {message}
          <div
            className={currentArrowClass}
            ref={setArrowElement}
            style={styles.arrow}
          />
        </div>
      )}
    </>
  );
};

TooltipMessage.defaultProps = {
  message: "",
  tooltipClass:
    "w-80 h-fit py-1 px-3 text-white font-medium leading-6 rounded-lg bg-border-blue tooltip-position",
  imageClass: "inline mb-2",
  arrowClass:
    "w-2.5 h-2.5 before:transform-y-[10px] before:w-2.5 before:h-2.5 bg-border-blue before:bg-border-blue before:visible invisible before:absolute before:rotate-45 arrow-position",
};

export default TooltipMessage;
