/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import i18n from "#translate/i18n"
import moment from "moment"
import EventComponent from "#components/homePage/upcomingEvents/EventComponent"
import CarouselSlider from "#components/CarouselSlider"
import fetchUsersUpcomingEvents from "#requests/users/fetchUsersUpcomingEvents"

export default function UsersUpcomingEvents() {
  const sliderRef = useRef();
  const [usersUpcomingEvents, setUsersUpcomingEvents] = useState();
  const [loading, setLoading] = useState(true);

  const today = moment();

  const createDateForComparisonForSorting = (eventDate, eventName) => {
    const [year, month, day] = eventDate.split('-').map(Number);
    const eventMonth = month - 1;
    const eventDay = day;
    const currentYear = today.year();

    if (eventName === 'company_anniversary' || eventName === 'birth_date') {
      // Create the anniversary date for this year
      let thisYearDate = moment().year(currentYear).month(eventMonth).date(eventDay);

      // Check if the date has already passed this year
      if (thisYearDate.isBefore(today, 'day')) {
        // If it has passed, use the next year
        thisYearDate = thisYearDate.add(1, 'year');
      }
      return thisYearDate;
    } else {
      return moment().year(year).month(month - 1).date(day);
    }
  };

  useEffect(() => {
    fetchUsersUpcomingEvents()
      .then((response) => response.json())
      .then((parsedResponse) => {
        const currentEvents = Object.values(parsedResponse).flat().filter(item => item)
        const eventsSortedStartDates = currentEvents.sort((a, b) => {
          const dateA = createDateForComparisonForSorting(a.event_data.event_date, a.event_name);
          const dateB = createDateForComparisonForSorting(b.event_data.event_date, b.event_name);
          return dateA - dateB;
        });
        setUsersUpcomingEvents(eventsSortedStartDates)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching events:', error);
        setLoading(false);
      });
  }, []);

  return(
    <div>
      <p className="font-sans w-full text-2xl font-semibold text-black-report mb-[22px]">
        {i18n.t("homePage.upcomingEvents.sectionTitle")}
      </p>
      {
        !loading && usersUpcomingEvents && (
          <CarouselSlider
            cardComponent={({data}) => (
              <div className="h-[270px] lg:h-[286px] xl:h-[286px] 2xl:h-[270px] w-[312px]" >
                <EventComponent eventData={data} />
              </div>
            )}
            cardsData={usersUpcomingEvents}
            sliderRef={sliderRef}
            cardWidth={312}
          />
        )
      }
    </div>
  )
}
