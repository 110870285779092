/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import i18n from '#translate/i18n';
import SaveButton from "#components/buttons/medium/PrimaryButton"
import CancelButton from "#components/buttons/medium/TextButton"
import DivisionLine from '#components/formComponents/DivisionLine';
import TextAreaInput from '#components/formComponents/TextAreaInput';
import createOrUpdateMealAllowance from '#requests/finances/allowances/createOrUpdateMealAllowance';
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'
import addMealAllowanceSchema from '#validations/addMealAllowanceSchema';
import { mealAllowanceData as initialValues } from '#data/mealAllowanceData';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadedCurrencyValueInput from './LoadedCurrencyValueInput'

import { UserContext } from "#providers/UserContextProvider"

export default function MealDrawer({
  registeredAllowances,
  setRegisteredAllowances,
}) {
  const formName = 'meal_allowance_form'
  const model = 'meal_allowance'
  const mealAttr = ['notes']
  const [submittingData, setSubmittingData] = useState(false)

  const {
    setShowMessage,
    setObjectId: setEditObjectId,
    objectId: editObjectId,
    selectedPerson: user,
    setRefreshUserData,
    handleDrawersClose,
    userName,
  } = useContext(UserContext)

  const closeAddAllowancesDrawer = () => {
    handleDrawersClose({ drawersToClose: ['allowances'], clearData: false })
    setRegisteredAllowances('')
    setRefreshUserData(true)
  }

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addMealAllowanceSchema)
  });

  useEffect(() => {
    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'meal' })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          mealAttr.forEach(element => {
            formMethods.setValue(`${model}.${element}`, data[element])
          });
        })
    }
  }, []);

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdateMealAllowance({ data: data, user: user, objectId: editObjectId })
      .then(() => {
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'meal_allowance_updated' : 'meal_allowance_created'
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        setRefreshUserData(true)
        closeAddAllowancesDrawer()
      }).catch(function (error) {
        setSubmittingData(false)
        throw new Error(error);
      })
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={formName}
        className='overflow-y-auto max-h-[70vh] bg-white absolute
                   sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                   bottom-[100px] lg:bottom-[105px] xl:bottom-[100px]
                   right-0 left-0 mx-6 px-0.5'
        onSubmit={formMethods.handleSubmit(submitData)}
      >
        <div className='grid grid-cols-1 gap-y-3 gap-x-4 mb-5'>
          <LoadedCurrencyValueInput
            model={model}
            registeredAllowances={registeredAllowances}
            fieldName='monthly_value'
            allowanceName='meal'
          />
          <TextAreaInput
            model={model}
            name='notes'
            placeholder={i18n.t('meal_allowance.notes_placeholder')}
            title={i18n.t('meal_allowance.notes')}
            className='w-full'
          />
        </div>
        <div id='add_meal_allowance_forms_buttons' className='fixed bottom-0 right-0 sm:w-[70%] 2xl:w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'>
          <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
            <DivisionLine customStyle='mb-2.5' />
            <CancelButton
              buttonId={'meal-form-cancel-button'}
              defaultText={i18n.t('buttons.cancel')}
              onClick={closeAddAllowancesDrawer}
              disabled={submittingData}
              imagePresent={false}
              innerDivClass={'grid'}
              type={'button'}
              buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
            />

            <SaveButton
              form={formName}
              defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
              submittingData={submittingData}
              processingText={i18n.t("buttons.saving")}
              buttonId={"meal-form-submit-button"}
              buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
              type={'submit'}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
