/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import i18n from '#translate/i18n';

import { UserContext } from "#providers/UserContextProvider"

import SaveButton from "#components/buttons/medium/PrimaryButton"
import CancelButton from "#components/buttons/medium/TextButton"
import DivisionLine from '#components/formComponents/DivisionLine';
import TextAreaInput from '#components/formComponents/TextAreaInput';
import createOrUpdateParentalLeave from '#requests/finances/allowances/createOrUpdateParentalLeave';
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'
import addParentalLeaveSchema from '#validations/addParentalLeaveSchema';
import { parentalLeaveAllowanceData as initialValues } from '#data/parentalLeaveAllowanceData';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadedReturnDateInput from './LoadedReturnDateInput'
import ErrorMessage from '#components/messages/ErrorMessage'

export default function ParentalLeaveDrawer({
  registeredAllowances,
  setRegisteredAllowances,
}) {
  const formName = 'parental_leave_form'
  const model = 'parental_leave'
  const parentalLeaveAttr = ['notes', 'start_date']
  const [submitRequestErrors, setSubmitRequestErrors] = useState()
  const [submittingData, setSubmittingData] = useState(false)

  const {
    setShowMessage,
    setObjectId: setEditObjectId,
    objectId: editObjectId,
    selectedPerson: user,
    setRefreshUserData,
    refreshUserData,
    handleDrawersClose,
    userName,
  } = useContext(UserContext)

  const closeAddAllowancesDrawer = () => {
    handleDrawersClose({ drawersToClose: ['allowances'], clearData: false })
    setRegisteredAllowances('')
    setRefreshUserData(true)
  }

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addParentalLeaveSchema)
  });

  useEffect(() => {
    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'parental_leave' })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          parentalLeaveAttr.forEach(element => {
            formMethods.setValue(`${model}.${element}`, data[element])
          });
        })
    }
  }, []);

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdateParentalLeave({ data: data, user: user, model: model, objectId: editObjectId })
      .then(() => {
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'parental_leave_updated' : 'parental_leave_created',
        }
        setShowMessage(messages => [newMessage, ...messages])
        setRefreshUserData(!refreshUserData)
        setEditObjectId?.(null)
        setRefreshUserData(true)
        closeAddAllowancesDrawer()
      }).catch(function (requestReturn) {
        setSubmittingData(false)
        setSubmitRequestErrors(requestReturn.response.data.errors)
      })
  };

  useEffect(() => {
    if (!submitRequestErrors) { return }

    if (submitRequestErrors.start_date) {
      formMethods.setError(
        `${model}.start_date`, { type: "custom", message: i18n.t('parental_leave.messageForm.error.basic_server_error') }
      )
    }
  }, [submitRequestErrors]);

  return (
    <FormProvider {...formMethods}>
      <form
        id={formName}
        className='overflow-y-auto max-h-[70vh] bg-white absolute
                   sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                   bottom-[100px] lg:bottom-[105px] xl:bottom-[100px]
                   right-0 left-0 mx-6 px-0.5'
        onSubmit={formMethods.handleSubmit(submitData)}
      >
        <div className='grid grid-cols-1 gap-y-3 gap-x-4'>
          {submitRequestErrors && (
            <div className='grid grid-cols-1'>
              <ErrorMessage
                errors={submitRequestErrors}
                removeErrors={setSubmitRequestErrors}
                model={model}
                userName={userName}
              />
            </div>
          )}
        </div>
        <div className='grid grid-cols-2 gap-y-3 gap-x-4'>
          <LoadedReturnDateInput
            model={model}
            registeredAllowances={registeredAllowances}
            fieldName='return_date'
            allowanceName='parental_leave'
            referenceDate='start_date'
            editObjectId={editObjectId}
          />
        </div>
        <div className='grid grid-cols-1 gap-y-3 gap-x-4 mt-2 mb-5'>
          <TextAreaInput
            model={model}
            name='notes'
            placeholder={i18n.t('parental_leave.notes_placeholder')}
            title={i18n.t('parental_leave.notes')}
            className='w-full'
          />
        </div>
        <div id='add_parental_leave_forms_buttons' className='fixed bottom-0 right-0 w-1/2 sm:w-[70%] 2xl:w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'>
          <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
            <DivisionLine customStyle='mb-2.5' />
            <CancelButton
              buttonId={'parental-leave-forms-cancel-button'}
              defaultText={i18n.t('buttons.cancel')}
              onClick={() => closeAddAllowancesDrawer()}
              disabled={submittingData}
              imagePresent={false}
              innerDivClass={'grid'}
              type={'button'}
              buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
            />

            <SaveButton
              form={formName}
              defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
              submittingData={submittingData}
              processingText={i18n.t("buttons.saving")}
              buttonId={"parental-leave-form-submit-button"}
              buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
              type={'submit'}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
