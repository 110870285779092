import * as Yup from "yup";
import i18n from "#translate/i18n";
import { validateRejectStartAndEndDatesInterval } from "../../utils/schemas";
import { validateDateStringMax, validateDateStringMin, validateVacationDateStringMin } from "#utils/schemas";


Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

Yup.addMethod(Yup.string, "validateRejectStartAndEndDatesInterval", validateRejectStartAndEndDatesInterval);
Yup.addMethod(Yup.string, "validateDateStringMin", validateDateStringMin);
Yup.addMethod(Yup.string, "validateVacationDateStringMin", validateVacationDateStringMin);
Yup.addMethod(Yup.string, "validateDateStringMax", validateDateStringMax);

const VacationBaseSchema = Yup.object().shape({
  start_date: Yup.string()
                 .required(i18n.t("vacation.forms_error.presence"))
                 .validateRejectStartAndEndDatesInterval({
                   attributeName: 'start_date',
                   firstStartDateReference: 'vacation_last_start_date_input',
                   firstEndDateReference: 'vacation_last_return_date_input',
                   currentEndDateReference: 'vacation_return_date_input',
                   errorMessage: i18n.t("vacation.forms_error.time_interval")
                 })
                 .validateRejectStartAndEndDatesInterval({
                  attributeName: 'start_date',
                  firstStartDateReference: 'vacation_first_start_date_input',
                  firstEndDateReference: 'vacation_first_return_date_input',
                  currentEndDateReference: 'vacation_return_date_input',
                  errorMessage: i18n.t("vacation.forms_error.time_interval")
                 })
                .validateVacationDateStringMin({
                  attributeName: "start_date",
                  shouldAddDays: false,
                  docDateReference: 'vacation_min_date_input'
                })
                .validateDateStringMax({
                  attributeName: "birthdate",
                  docDateReference: 'vacation_max_date_input',
                  errorMessage: i18n.t("vacation.forms_error.date_max")
                }),
  return_date: Yup.string()
                  .typeError(i18n.t("vacation.forms_error.presence"))
                  .required(i18n.t("vacation.forms_error.presence"))
                  .validateDateStringMin({
                    attributeName: "return_date",
                    shouldAddDays: true,
                    docDateReference: 'vacation_start_date_input',
                    numberOfDaysToAdd: 1,
                    errorMessage: i18n.t("vacation.forms_error.min_today")
                  })
                  .validateDateStringMin({
                    attributeName: "return_date",
                    shouldAddDays: true,
                    docDateReference: 'vacation_start_date_input',
                    docDaysToAddReference: 'vacation_total_business_days_input',
                    errorMessage: i18n.t("vacation.forms_error.time_sequence")
                  }),
  total_business_days: Yup.string()
                          .typeError(i18n.t("vacation.forms_error.presence"))
                          .required(i18n.t("vacation.forms_error.presence")),
  bonus_added: Yup.boolean()
                  .typeError(i18n.t("vacation.forms_error.presence"))
                  .required(i18n.t("vacation.forms_error.presence")),
  notes: Yup.string()
            .nullable(),
});

export default VacationBaseSchema;
