/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import i18n from "#translate/i18n";

import { UserFormsContext } from "#providers/UserFormsProvider";

import Person from "#components/listings/Person";
import LoadingPage from '#components/navigation/LoadingPage';
import NotFound from '#components/navigation/NotFound';

export function People({
  people,
  openNewUserForm,
  setSelectedUsers,
  selectedUsers,
  selectAll,
  setSelectAll,
  unselectedAll,
  setUnselectedAll
}) {
  const { openUserEditForms } = useContext(UserFormsContext);
  const [openMenu, setOpenMenu] = useState('')
  const bodyTableRef = useRef(null);
  const headerTableRef = useRef(null);

  const handleRowSelection = (id, selected) => {
    let newSelectedIds = [...selectedUsers];
    const foundPerson = people.find(person => person.id === id)
    if (selected) {
      newSelectedIds.push(foundPerson);
    } else {
      newSelectedIds = newSelectedIds.filter(person => person.id !== id);
    }

    newSelectedIds = [...new Set(newSelectedIds)];
    setSelectedUsers(newSelectedIds);
    setSelectAll(newSelectedIds.length >= people.length);
  };

  const handleSelectAll = (event) => {
    let checked = event.target.checked;
    setSelectAll(checked);
    setUnselectedAll(!checked);

    if (checked) {
      setSelectedUsers(people);
    } else {
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    const bodyTable = bodyTableRef.current;

    const handleScroll = () => {
      if (headerTableRef.current) {
        headerTableRef.current.scrollLeft = bodyTable.scrollLeft;
      }
    };

    bodyTable.addEventListener('scroll', handleScroll);

    return () => {
      bodyTable.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const thClasses = "sticky top-0 bg-light-gray uppercase font-semibold font-sans text-xs text-left text-slate-600 pl-5 py-5 box-border inline-block 2k-monitor:table-cell";

  return (
    <>
      <div className={`loading w-full ${people.length && !people[0].id ? "" : "hidden" }`}><LoadingPage /></div>
      {people.length === 0 && <NotFound />}
      {/* Header Table */}
      <div
        ref={headerTableRef}
        className={`sticky top-0 z-[1] bg-white overflow-hidden w-full ${openUserEditForms || openNewUserForm || !people || people.length === 0 ? 'hidden' : ''}`}
      >
        <table
          className="border-collapse p-0 rounded-lg drop-shadow-md w-full table-fixed"
          id="table-people"
        >
          <thead className="w-full border-collapse h-[58px] bg-light-gray contents">
            <tr className="w-full whitespace-nowrap bg-light-gray">
              <th className={`${thClasses} text-center left-0 z-[1000] bg-light-gray w-16`}>
                <input
                  aria-labelledby="select-all"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="w-4 h-4 rounded border-slate-300 z-1000" id="checkbox_select_all"
                />
                <label hidden id="select-all">{i18n.t('peopleListing.selectAll')}</label>
              </th>
              <th className={`${thClasses} sticky left-16 z-[1000] w-[383.88px]`} >
                {i18n.t('peopleListing.people')}
              </th>
              <th className={`${thClasses} z-10 w-[120.83px]`} >
                {i18n.t('peopleListing.client')}
              </th>
              <th className={`${thClasses} z-10 w-[121.39px]`} >
                {i18n.t('peopleListing.birthdate')}
              </th>
              <th className={`${thClasses} z-10 w-[200.84px]`} >
                {i18n.t('peopleListing.workField')}
              </th>
              <th className={`${thClasses} z-10 w-[176.13px]`} >
                {i18n.t('peopleListing.status')}
              </th>
              <th className={`${thClasses} z-10 w-[121.92px]`} >
                {i18n.t('peopleListing.startedInCompanyDate')}
              </th>
              <th className={`${thClasses} z-10 w-[167.78px]`} >
                {i18n.t('peopleListing.houseTime')}
              </th>
              <th className={`${thClasses} z-10 w-[135.55px]`} >
                {i18n.t('peopleListing.salary')}
              </th>
              <th className={`${thClasses} z-10 w-[136.95px]`} >
                {i18n.t('peopleListing.lastRaise')}
              </th>
              <th className={`${thClasses} z-10 right-0 w-[82.73px]`} >
                {i18n.t('peopleListing.actions')}
              </th>
            </tr>
          </thead>
        </table>
      </div>

      {/* Body Table */}
      <div
        ref={bodyTableRef}
        className={`h-[calc(100%-58px)] w-full overflow-x-auto overflow-y-auto ${openUserEditForms || openNewUserForm || !people || people.length === 0 ? 'hidden' : ''}`}
      >
        <table className="border-collapse rounded-lg drop-shadow-md w-full table-fixed" id="table-people-body">
          <tbody className="bg-white">
            {people.map((person, index) => (
              <Person key={index}
                person={person}
                selectAll={selectAll}
                unselectedAll={unselectedAll}
                selectedUsers={selectedUsers}
                handleRowSelection={handleRowSelection}
                row={index}
                peopleLength={people.length}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="
        w-0 h-0 border-support-frozen bg-support-frozen
        border-light-support-frozen
        border-light-support-positive bg-support-positive
        border-light-support-error bg-support-error
        border-light-support-alert bg-support-alert
      "/>
    </>
  );
}
