import React, { useState, useEffect, useContext } from 'react';
import i18n from "#translate/i18n";
import Title from '#components/user/profile/Title';
import apartmentIcon from "#images/apartment_icon.svg";
import historicIcon from "#images/historic_icon.svg";
import CompanyHistoric from '#components/user/profile/historicTab/CompanyHistoric';
import Timeline from '#components/user/profile/historicTab/Timeline';
import UserEventsRequest from '#requests/userEventsRequest';

import { UserContext } from "#providers/UserContextProvider"

export default function HistoricTab({ userData, handleOpenedDrawersFromProfile }) {
  const i18nCompanyName = i18n.t(`userProfile.company_historic.${userData.company_history.company}`)
  const { refreshUserData, setProfileTabName } = useContext(UserContext)

  const [userEventsData, setUserEventsData] = useState([]);

  useEffect(() => {
    async function fetchUserEvents() {
      const userEvents = await UserEventsRequest(userData.id);
      setUserEventsData(userEvents);
    }

    fetchUserEvents();
  }, [userData, refreshUserData]);

  useEffect(() => {
    setProfileTabName('historic')
  }, []);

  return (
    <>
      <div>
        <Title
          title={`${i18n.t('userProfile.company_historic.title')} ${i18nCompanyName}`}
          icon={apartmentIcon}
        />
        <CompanyHistoric userData={userData}
        />
      </div>
      {userEventsData.length > 0 && (
        <div>
          <Title
            title={`${i18n.t('userProfile.company_historic.subtitle')}`}
            icon={historicIcon}
            id='userProfile-company-historic-subtitle'
          />
          <div className="my-5">
            <Timeline
              userEventsData={userEventsData}
              userData={userData}
              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
            />
          </div>
        </div>
      )}
    </>
  );
}
