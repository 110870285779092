import React from "react"
import { Link } from "react-router-dom";
import i18n from '#translate/i18n'
import TickSymbol from '#images/bonus_drawer/tick_symbol.svg'
import DisabledTickSymbol from '#images/buttons/tick_disabled_submit.svg';
import RightIcon from '#images/buttons/right_icon_arrow.svg'
import ProcessingRequestImage from '#images/buttons/processing_request.svg'

export default function PrimaryButton({
  form,
  defaultText,
  processingText,
  onClick,
  submittingData,
  defaultButtonImage = 'tickSymbol',
  buttonClass,
  innerDivClass,
  disabled,
  iconPresent = false,
  imagePresent = true,
  buttonId,
  pressed,
  type,
  isLink = false,
  linkTo = "",
  ...props
}) {
  const images = {
    tickSymbol: TickSymbol
  }

  const disabledImages = {
    tickSymbol: DisabledTickSymbol
  }

  function defaultButton() {
    return (
      <button
        type={type}
        id={buttonId}
        form={form}
        onClick={onClick}
        disabled={disabled}
        className={`mx-2 font-sans text-base font-semibold py-2.5 px-4 rounded w-32 h-11
        ${disabled ? 'bg-border-gray text-placeholder-gray' : pressed ? 'bg-rebase-navy-blue' : 'text-white bg-rebase-blue hover:bg-rebase-navy-blue hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] focus:shadow-[0px_0px_4px_0px_rgb(50,197,255)] focus:bg-rebase-blue'}
        ${buttonClass}`}
        {...props}
      >
        <div className={`flex flex-row gap-2.5 ${innerDivClass}`} >
          {imagePresent && (
            <img
              className='w-[18px] h-[18px] mt-[3px]'
              src={disabled ? disabledImages[defaultButtonImage] : images[defaultButtonImage]}
              alt={i18n.t(`buttons.${defaultButtonImage}`)}
            />
          )}
          <p>{defaultText || i18n.t(`buttons.${defaultText}`)}</p>
          {iconPresent && (
            <img
              className='w-[18px] h-[18px] mt-[3px]'
              src={RightIcon}
              alt={i18n.t(`buttons.rightIcon`)}
            />
          )}
        </div>
      </button>
    )
  }

  function processingButton() {
    return (
      <button
        id={buttonId}
        form={form}
        disabled={true}
        className={`mx-2 font-sans text-base font-semibold py-2.5 px-4 focus:border-4 text-white bg-rebase-navy-blue rounded w-32 h-11 ${buttonClass}`}
        {...props}
      >
        <div className={`flex flex-row gap-2.5 ${innerDivClass}`} >
          <p>{processingText || i18n.t(`buttons.${processingText}`)}</p>
          <img
            className='w-[18px] h-[18px] mt-[3px]'
            src={ProcessingRequestImage}
            alt={i18n.t('buttons.processing')}
          />
        </div>
      </button>
    )
  }

  function defaultLink() {
    return (
      <Link
        to={linkTo}
        id={buttonId}
        className={`mx-2 font-sans text-base font-semibold py-2.5 px-4 rounded w-32 h-11 text-white bg-rebase-blue hover:bg-rebase-navy-blue hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] focus:shadow-[0px_0px_4px_0px_rgb(50,197,255)] focus:bg-rebase-blue ${buttonClass}`}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        <div className={`flex flex-row gap-2.5 ${innerDivClass}`} >
          {imagePresent && (
            <img
              className='w-[18px] h-[18px] mt-[3px]'
              src={images[defaultButtonImage]}
              alt={i18n.t(`buttons.${defaultButtonImage}`)}
            />
          )}
          <p>{defaultText || i18n.t(`buttons.${defaultText}`)}</p>
          {iconPresent && (
            <img
              className='w-[18px] h-[18px] mt-[3px]'
              src={RightIcon}
              alt={i18n.t(`buttons.rightIcon`)}
            />
          )}
        </div>
      </Link>
    )
  }

  return (
    <>
      {submittingData ? processingButton() : isLink ? defaultLink() : defaultButton()}
    </>
  );
}
