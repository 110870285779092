/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import i18n from '#translate/i18n';
import PeopleImage from '#images/bonus_drawer/header_people.svg';
import GiftImage from '#images/bonus_drawer/gift.svg';
import DisabledTickSymbol from '#images/buttons/tick_disabled_submit.svg';
import CloseButton from '#images/add_allowances/close_icon.svg';
import DivisionLine from '#components/formComponents/DivisionLine';
import HomeOfficeDrawer from './HomeOfficeDrawer'
import MealDrawer from './MealDrawer'
import YearEndBonusDrawer from './YearEndBonusDrawer'
import VacationDrawer from '#components/finances/allowances/VacationDrawer';
import HealthcareDrawer from './HealthcareDrawer'
import ParentalLeaveDrawer from './ParentalLeaveDrawer'
import PsychologicalSupportDrawer from "./PsychologicalSupportDrawer"
import AllowancesMenu from './AllowancesMenu'
import fetchUserAllowancesAvailabilities from '#requests/finances/allowances/fetchUserAllowancesAvailabilities';
import useOnClickOutside from '#services/useOnClickOutside';

import { UserContext } from "#providers/UserContextProvider"

export default function SelectionDrawer() {
  const {
    selectedPerson: user,
    handleDrawersClose,
    objectId: editObjectId,
    chosenAllowanceName,
    setRefreshUserData,
    handleDrawersOpen,
    menuOrigin
  } = useContext(UserContext)

  const userName= user.presentedName

  const closeAddAllowancesDrawer = () => handleDrawersClose({ drawersToClose: ['allowances'], clearData: false })

  const [openHomeOfficeAllowanceDrawer, setOpenHomeOfficeAllowanceDrawer] = useState(false);
  const [openMealAllowanceDrawer, setOpenMealAllowanceDrawer] = useState(false);
  const [openYearEndBonusDrawer, setOpenYearEndBonusDrawer] = useState(false);
  const [openVacationDrawer, setOpenVacationDrawer] = useState(false);
  const [openParentalLeaveDrawer, setOpenParentalLeaveDrawer] = useState(false);
  const [openHealthcareDrawer, setOpenHealthcareDrawer] = useState(false);
  const [openPsychologicalSupportDrawer, setOpenPsychologicalSupportDrawer] = useState(false);
  const [chosenAllowance, setChosenAllowance] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [registeredAllowances, setRegisteredAllowances] = useState(['']);
  const allowancesDrawer = useRef();
  const openDrawersNames = {
    homeOffice: setOpenHomeOfficeAllowanceDrawer,
    meal: setOpenMealAllowanceDrawer,
    psychological_support: setOpenPsychologicalSupportDrawer,
    year_end_bonus: setOpenYearEndBonusDrawer,
    parental_leave: setOpenParentalLeaveDrawer,
    vacation: setOpenVacationDrawer,
    healthcare: setOpenHealthcareDrawer,
  }

  const closeDrawerAndCleanData = () => {
    closeAddAllowancesDrawer()
    setRegisteredAllowances('')
    setRefreshUserData(true)
    if (menuOrigin['profile']) {
      handleDrawersOpen(user, 'profile')
    }
  }

  const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

  useOnClickOutside({
    ref: allowancesDrawer,
    handler: closeDrawerAndCleanData,
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  })

  useEffect(() => {
    fetchUserAllowancesAvailabilities({ user: user })
      .then((response) => response.json())
      .then((parsedResponse) => {
        setRegisteredAllowances(parsedResponse);
        if (chosenAllowanceName && editObjectId) {
          openDrawersNames[chosenAllowanceName](true)
          const name = camelToSnakeCase(chosenAllowanceName)
          setChosenAllowance(name)
          setDisableSubmitButton(false)
        }
      })
  }, [])

  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1500] h-screen' id="forms-drawer-bg">
      <div
        ref={allowancesDrawer}
        className="bg-white absolute right-0 top-0 bottom-0 rounded-none z-[1500] w-1/2 sm:w-[70%] 2xl:w-1/2
                   shadow-[0px_16px_24px_rgb(0,0,0,0.14),0px_6px_30px_rgb(0,0,0,0.12)]"
        id='allowances-drawer'
      >
        <div id='add_allowances_body' className="w-full h-full" >
          <div id='add_allowances_text_header' className="block h-fit" >
            <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} flex justify-center z-[1600]
                            h-[90px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px]
                            max-h-[15vh]`}
            >
              <img
                className='text-center h-[95%] self-end'
                src={PeopleImage}
                alt={i18n.t('bonusDrawer.peopleHeader')}
              />
              <button
                id='close_selection_drawer_button'
                type='button'
                className='absolute top-5 right-5'
                onClick={closeDrawerAndCleanData}
              >
                <img
                  id='close_selection_drawer_image'
                  src={CloseButton}
                  alt={i18n.t('add_allowances.close_icon')}
                  className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                />
              </button>
            </div>
            <div className='m-4 flex' >
              <div className="mt-0 mr-4 z-[1650] bg-white">
                <img
                  src={GiftImage}
                  alt={i18n.t('bonusDrawer.gift')}
                />
              </div>
              <div className="mt-0 z-[1600]">
                <h5 className='font-sans not-italic font-bold text-xl/6'>
                  {i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.title`)}
                </h5>
                <h6 className='font-sans not-italic text-sm/6'>
                  <p className='mt-[5px]'>
                    {i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.subtitle`)}<span className='font-semibold'>{userName}</span>.
                  </p>
                </h6>
              </div>
            </div>
          </div>
          <div id='add_allowances_main_body'
            className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0
                        sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0"
          >
            <div>
              <div className='font-sans not-italic font-medium text-sm text-title-gray
                              drop-shadow-[0px_1px_2px_rgba(16,24,40,0.05),0px_0px_0px_#F4EBFF]'
              >
                {i18n.t('add_allowances.select_names_title')}
              </div>
              <AllowancesMenu
                setOpenHomeOfficeAllowanceDrawer={setOpenHomeOfficeAllowanceDrawer}
                setOpenMealAllowanceDrawer={setOpenMealAllowanceDrawer}
                setOpenVacationDrawer={setOpenVacationDrawer}
                setOpenPsychologicalSupportDrawer={setOpenPsychologicalSupportDrawer}
                setOpenYearEndBonusDrawer={setOpenYearEndBonusDrawer}
                setOpenParentalLeaveDrawer={setOpenParentalLeaveDrawer}
                setOpenHealthcareDrawer={setOpenHealthcareDrawer}
                setDisableSubmitButton={setDisableSubmitButton}
                chosenAllowance={chosenAllowance}
                setChosenAllowance={setChosenAllowance}
                registeredAllowances={registeredAllowances}
              />
            </div>
            <div id='add_allowances_drawers' className='p-0 bg-white' >
              {
                openMealAllowanceDrawer &&
                <MealDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openPsychologicalSupportDrawer &&
                <PsychologicalSupportDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openHomeOfficeAllowanceDrawer &&
                <HomeOfficeDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openYearEndBonusDrawer &&
                <YearEndBonusDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openVacationDrawer &&
                <VacationDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openHealthcareDrawer &&
                <HealthcareDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
              {
                openParentalLeaveDrawer &&
                <ParentalLeaveDrawer
                  registeredAllowances={registeredAllowances}
                  setRegisteredAllowances={setRegisteredAllowances}
                />
              }
            </div>
            {
              disableSubmitButton &&
              <div id='position_add_allowances_buttons'
                className='absolute bottom-0 right-0 w-full flex flex-row max-h-[15vh] bg-white h-[120px]'>
                <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
                  <DivisionLine customStyle='mb-2.5' />
                  <button
                    id='allowance_cancel_button'
                    type='button'
                    className='absolute right-[275px] justify-center flex-none font-sans Helvetica Neue font-medium rounded-md flex items-center
                                text-base w-[70px] h-11 bg-white text-rebase-blue float-left mt-2.5'
                    onClick={closeDrawerAndCleanData}
                  >
                    {i18n.t('buttons.cancel')}
                  </button>
                  <button
                    type='button'
                    className='absolute right-0 flex justify-center items-cente flex-none font-sans Helvetica Neue font-medium text-base
                                grid w-[211px] h-11 bg-border-gray text-placeholder-gray mr-5 flex-row py-2.5 px-4 rounded grid-cols-[20px_200px] mt-2.5'
                    id='new_allowance_button_disabled'
                    disabled={disableSubmitButton}
                  >
                    <div>
                      <img
                        className='absolute left-3 top-[13px] w-[18px] h-[18px]'
                        src={DisabledTickSymbol}
                        alt={i18n.t('bonusDrawer.tickSymbol')}
                      />
                    </div>
                    <span className='font-sans not-italic font-semibold text-base text-placeholder-gray'>
                      {i18n.t('add_allowances.submit_button_text')}
                    </span>
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
