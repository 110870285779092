import React from 'react'
import i18n from "#translate/i18n"
import moment from "moment"

import OptionsMenu from '#components/user/profile/remunerationTab/OptionsMenu';

import psychologicalSupport from '#images/events_icons/psychological_support.svg';
import calendarIcon from '#images/calendar-blue.svg';

export default function ActivePsychologicalSupport({
  allowanceData,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenObjectRemoval,
  handleOpenedDrawersFromProfile,
  userData,
  validOptions
}) {
  const endDate = allowanceData.sessions_end_date ? moment(allowanceData.sessions_end_date, "YYYY-MM-DD") : ""
  const isConcluded = endDate ? endDate.endOf('day') < moment().endOf('day') : false

  return (
    <div id='user-active-psychological-support' className='w-full py-4 px-0 rounded border border-solitude gap-4 grid' >
      <div className='py-0 px-4 gap-3 flex' >
        <div className='gap-3 flex w-full' >
          <div className='h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto' >
            <div className='rounded-full shadow-timeline-icons'>
              <img
                src={psychologicalSupport}
                alt="event icon"
                className='w-11 h-11 p-1'
              />
            </div>
          </div>
          <div className='w-[90%]'>
            <p className='font-sans text-lg/[30px] font-semibold text-left text-midnight w-[90%] absolute' >
              {i18n.t('userProfile.remuneration_tab.active_allowances.psychological_support.title')}
            </p>
            <OptionsMenu
              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
              setObjectId={setObjectId}
              setChosenObjectRemoval={setChosenObjectRemoval}
              chosenObjectData={allowanceData}
              objectType='ActivePsychologicalSupport'
              isValid={!validOptions}
              isRemovable={!isConcluded}
              isUpdatable={!isConcluded}
              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
              userData={userData}
            />
            <div className='flex gap-2 items-center mt-8' >
              <div className='flex gap-2' >
                <img src={calendarIcon} alt='calendar_image' />
                <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                  {i18n.t('userProfile.remuneration_tab.active_allowances.psychological_support.monthly_sessions', { count: allowanceData.monthly_sessions })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
