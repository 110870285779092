import React, { useContext } from "react";
import Bonus from '#components/finances/drawers/Bonus'
import SelectionDrawer from '#components/finances/allowances/SelectionDrawer'
import ExpenseAssistance from '#components/finances/drawers/ExpenseAssistance'
import SalaryReadjustment from '#components/finances/drawers/SalaryReadjustment'
import WorkLeave from '#components/finances/drawers/WorkLeave'
import UserDismissal from "#components/finances/drawers/dismissal/UserDismissal";

import { UserContext } from "#providers/UserContextProvider"

export default function FinancialAdditions() {
  const {
    selectedPerson,
    openedDrawers,
  } = useContext(UserContext)

  return (
    <>
      { selectedPerson && openedDrawers['expenseAssistance'] && (<ExpenseAssistance />) }

      { selectedPerson && openedDrawers['bonus'] && (<Bonus />) }

      { selectedPerson && openedDrawers['salaryReadjustment'] && (<SalaryReadjustment />) }

      { selectedPerson && openedDrawers['allowances'] && (<SelectionDrawer />) }

      { selectedPerson && openedDrawers['workLeave'] && (<WorkLeave />) }

      { selectedPerson && openedDrawers['userDismissal'] && (<UserDismissal />) }
    </>
  );
}
