import React, { useRef, useState, useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import i18n from "#translate/i18n";

import { UserContext } from "#providers/UserContextProvider"

import PersonImage from "#images/work_leave_drawer/header_person.svg";
import CalendarImage from "#images/work_leave_drawer/colorful_calendar.svg";
import CloseButton from '#images/add_allowances/close_icon.svg';
import docIcon from "#images/doc-icon.svg";

import SaveButton from "#components/buttons/medium/PrimaryButton"
import CancelButton from "#components/buttons/medium/TextButton"
import DivisionLine from "#components/formComponents/DivisionLine";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import DateInput from "#components/formComponents/DateInput";
import InputText from "#components/formComponents/InputText";
import UploadFile from "#components/uploadFile/UploadFile";
import ErrorMessage from '#components/messages/ErrorMessage'

import createOrUpdateWorkLeave from "#requests/finances/createOrUpdateWorkLeave";
import fetchWorkLeave from "#requests/userProfile/editions/fetchWorkLeave";
import workLeaveSchema from "#validations/workLeaveSchema";
import { workLeaveData as initialValues } from "#data/workLeaveData";
import { yupResolver } from "@hookform/resolvers/yup";
import useOnClickOutside from '#services/useOnClickOutside';

export default function WorkLeave() {
  const scrollDivRef = useRef();

  const formName = "work_leave_form"
  const model = "work_leave"

  const {
    setShowMessage,
    setObjectId: setEditObjectId,
    objectId: editObjectId,
    selectedPerson: user,
    setRefreshUserData,
    refreshUserData,
    handleDrawersClose,
    userName,
  } = useContext(UserContext)

  const closeWorkLeaveDrawer = () => {
    handleDrawersClose({ drawersToClose: ['workLeave'], clearData: false })
  }

  const fontStyle = "text-sm font-medium text-title-gray";

  const [serverErrors, setServerErrors] = useState()
  const [editData, setEditData] = useState('');
  const [submittingData, setSubmittingData] = useState(false);

  const todayStart = new Date()
  todayStart.setUTCHours(0, 0, 0, 0);

  const WorkLeaveAttr = ['reason', 'start_date', 'foreseen_return_date', 'notes', 'documentation']

  const workLeaveDrawer = useRef();
  useOnClickOutside({
    ref: workLeaveDrawer,
    handler: () => { closeWorkLeaveDrawer() },
    ignoredIds: ['general-success-message', 'general-success-message-close-img', 'profile-drawer']
  });

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(workLeaveSchema)
  });

  useEffect(() => {
    formMethods.setValue(`${model}.start_date_past`, false)
    formMethods.setValue(`${model}.foreseen_return_date_past`, false)

    if (editObjectId) {
      fetchWorkLeave({ id: editObjectId, user: user })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          const pastStart = data && new Date(`${data['start_date']}T00:00`) < todayStart
          const pastReturn = data && new Date(`${data['foreseen_return_date']}T00:00`) < todayStart

          setEditData({ start_date_past: pastStart, foreseen_return_date_past: pastReturn, ...data })

          WorkLeaveAttr.forEach(element => {
            formMethods.setValue(`${model}.${element}`, data[element] || "")
          });

          formMethods.setValue(`${model}.documentation_update`, data.documentation ? true : false)
          formMethods.setValue(`${model}.start_date_past`, pastStart)
          formMethods.setValue(`${model}.foreseen_return_date_past`, pastReturn)
        })
    }
  }, []);

  const onSubmit = (data) => {
    setSubmittingData(true)
    createOrUpdateWorkLeave({ data: data, model: model, user: user, objectId: editObjectId })
      .then(() => {
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'work_leave_updated' : 'work_leave_created',
        }
        setShowMessage(messages => [newMessage, ...messages])
        setEditObjectId?.(null)
        setRefreshUserData(!refreshUserData)
        setRefreshUserData(true)
        closeWorkLeaveDrawer()
      })
      .catch(function (requestReturn) {
        setSubmittingData(false)
        setServerErrors(requestReturn.response.data.errors)
      })
  };

  useEffect(() => {
    if (!serverErrors) { return }

    if (serverErrors.foreseen_return_date) {
      formMethods.setError(
        `${model}.start_date`, { type: "custom", message: i18n.t('work_leave.form_errors.basic_server_error') }
      )
      formMethods.setError(
        `${model}.foreseen_return_date`, { type: "custom", message: i18n.t('work_leave.form_errors.basic_server_error') }
      )
    }

    setTimeout(function () {
      scrollDivRef.current?.scrollTo({ top: 0 })
    }, 100)
  }, [serverErrors]);

  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1500] h-screen' id="forms-drawer-bg">
      <div
        ref={workLeaveDrawer}
        className='bg-white absolute right-0 top-0 bottom-0 rounded-none w-1/2 sm:w-[70%] 2xl:w-1/2 z-[1500]
                    shadow-[0px_16px_24px_rgb(0,0,0,0.14),0px_6px_30px_rgb(0,0,0,0.12)'
        id='work-leave-drawer'
      >
        <div className="w-full h-full">
          <div className="block h-fit" >
            <div className={`${editObjectId ? 'bg-complementary-yellow' : 'bg-rebase-green'} z-1600 flex
                            justify-center h-[90px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px] max-h-[15vh]`}
            >
              <img
                className="text-center h-[95%] self-end"
                src={PersonImage}
                alt={i18n.t("workLeaveDrawer.personHeader")}
              />
              <button
                data-testid='close_leave_drawer_button'
                id='close_leave_drawer_button'
                type='button'
                className='absolute top-5 right-5'
                onClick={() => { closeWorkLeaveDrawer() }}
              >
                <img
                  data-testid='close_selection_drawer_image'
                  src={CloseButton}
                  alt={i18n.t('buttons.logout')}
                  className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                />
              </button>
            </div>
            <div className='m-4 flex' >
              <div className="mt-0 mr-4 z-[1650] bg-white" >
                <img
                  src={CalendarImage}
                  alt={i18n.t("workLeaveDrawer.calendar")}
                />
              </div>
              <div className="mt-0 z-[1600]" >
                <h5 className="font-sans not-italic font-bold text-xl/6" >
                  {i18n.t(`${editObjectId ? 'work_leave_update' : 'work_leave_create'}.title`)}

                </h5>
                <h6 className="font-sans not-italic text-sm/6" >
                  <p className='mt-[5px]'>{i18n.t(`${editObjectId ? 'work_leave_update' : 'work_leave_create'}.subtitle`)}
                    <span className='font-semibold'> {userName}</span>.
                  </p>
                </h6>
              </div>
            </div>
          </div>
          <div className="block max-h-[85vh] px-6 pb-2.5 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0"
          >
            <FormProvider {...formMethods}>
              <form
                id={formName}
                onSubmit={formMethods.handleSubmit(onSubmit)}
              >
                <div
                  className="overflow-y-auto max-h-[70vh] absolute right-0 left-0 mx-6 px-0.5
                              sm:top-[165px] md:top-[165px] lg:top-[180px] xl:top-[191px] 2xl:top-[226px] 2k-monitor:top-[261px]
                              bottom-[100px] lg:bottom-[105px]"
                  ref={scrollDivRef}
                >
                  {serverErrors && (
                    <div className='grid grid-cols-1'>
                      <ErrorMessage
                        errors={serverErrors}
                        removeErrors={setServerErrors}
                        model={model}
                        userName={userName}
                      />
                    </div>
                  )}
                  <div className="grid grid-cols-1 mt-1">
                    <InputText
                      model={model}
                      name='reason'
                      placeholder={i18n.t('work_leave.reason_placeholder')}
                      title={i18n.t('work_leave.reason')}
                      className='w-full'
                      requiredField={true}
                      id={`${model}_reason_input`}
                    />
                  </div>
                  <div className='grid grid-cols-2 gap-y-3 gap-x-4 my-2.5'>
                    <div>
                      <DateInput
                        id={`${model}_start_date_input`}
                        requiredField={true}
                        model={model}
                        name="start_date"
                        title={<span className={fontStyle}>{i18n.t("work_leave.start_date")}</span>}
                        disabled={editData?.start_date_past === true ? true : false}
                        classStyle='w-full'
                      />
                    </div>
                    <div>
                      <DateInput
                        id={`${model}_foreseen_return_date_input`}
                        requiredField={true}
                        model={model}
                        name="foreseen_return_date"
                        title={<span className={fontStyle}>{i18n.t("work_leave.foreseen_return_date")}</span>}
                        disabled={editData?.foreseen_return_date_past === true ? true : false}
                        classStyle='w-full'
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <p className={fontStyle}>{i18n.t("work_leave.documentation.title")}</p>
                      <UploadFile
                        model={model}
                        id={`${model}_documentation_input`}
                        name="documentation"
                        documentImage={docIcon}
                        documentType={i18n.t("work_leave.documentation.title")}
                        alt={i18n.t("work_leave.documentation.alt")}
                        isBox
                      />
                    </div>

                    <TextAreaInput
                      model={model}
                      name="notes"
                      placeholder={i18n.t("work_leave.notes_placeholder")}
                      title={i18n.t("work_leave.notes")}
                      className='w-full mb-1'
                      id={`${model}_notes_input`}
                    />
                  </div>
                </div>
                <div data-testid={`${model}_forms_buttons`} className='absolute bottom-0 right-0 w-full flex flex-row max-h-[15vh] bg-white h-[100px]' >
                  <div className='absolute right-2.5 left-2.5 bottom-[78px]' >
                    <DivisionLine customStyle='mb-2.5' />
                    <CancelButton
                      buttonId={`${model}_cancel_button`}
                      defaultText={i18n.t('buttons.cancel')}
                      onClick={() => { closeWorkLeaveDrawer() }}
                      disabled={submittingData}
                      imagePresent={false}
                      innerDivClass={'grid'}
                      type={'button'}
                      buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[366px]'}`}
                    />

                    <SaveButton
                      form="work_leave_form"
                      defaultText={i18n.t(`${editObjectId ? 'work_leave_update' : 'work_leave_create'}.submit_button_text`)}
                      submittingData={submittingData}
                      processingText={i18n.t("buttons.saving")}
                      buttonId={"work-leave-submit-button"}
                      buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-[170px]' : 'w-[323px]'}`}
                      type={'submit'}
                    />
                  </div>
                </div>
                <input type='boolstringean' className="w-0 h-0" id={`${model}_start_date_past`} {...formMethods.register(`${model}.start_date_past`)} />
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
