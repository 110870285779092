export default async function userSessionRequest() {
  const url = '/api/v1/auth_user';

  try {
    const response = await fetch(url);
    if (!response.ok) {
      return window.location.replace(`/login?status=denied`)
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in userSessionRequest:', error);
    throw error;
  }
}
