import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import i18n from "#translate/i18n";
import LoadingPage from "#components/navigation/LoadingPage";
import fetchBiannualAbsences from "#requests/users/fetchBiannualAbsences"

export default function AbsencesGraphic() {
  const [absences, setAbsences] = useState("")
  const [loading, setLoading] = useState(true)


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    fetchBiannualAbsences()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setAbsences(parsedResponse)
        setLoading(false)
      });
  }, [])


  const barOptions = {
    locale: "pt-BR",
    layout: {
      padding: {
        top: -30,
        bottom: 5, // Add padding at the bottom
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#72777B",
          padding: 8
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        title: {
          display: true,
          text: i18n.t("homePage.absenceListing.distribution.xAxis"),
          padding: {
            top: 10
          },
          color: "#485668",
          font: {
            size: 12,
            weight: "normal"
          }
        },
        border: {
          color: '#E7EAEE'
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: i18n.t("homePage.absenceListing.distribution.yAxis"),
          color: "#485668",
          font: {
            size: 12,
            weight: "normal"
          },
        },
        ticks: {
          beginAtZero: true,
          color: "#72777B",
          padding: 8,
          stepSize: 1
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        border: {
          color: '#E7EAEE'
        }
      },
      secondYAxis: {
        type: "linear",
        position: "right",
        ticks: { display: false },
        grid: { display: false },
        border: {
          color: '#E7EAEE'
        }
      },
    },
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          boxHeight: 10,
          padding: 50,
          fullSize: true,
          color: "#2D2D2D",
        },
      },
      title: {
        display: false
      }
    }
  };

  const data = {
    labels: Object.keys(absences).map((x) => i18n.t(`homePage.month.${x}`)),
    datasets: [
      {
        label: i18n.t("homePage.absenceListing.distribution.vacation"),
        data: Object.keys(absences).map((x) => absences[x].vacation),
        backgroundColor: "#00C7F2",
        barPercentage: 0.5,
      },
      {
        label: i18n.t("homePage.absenceListing.distribution.parental_leave"),
        data: Object.keys(absences).map((x) => absences[x].parental_leave),
        backgroundColor: "#0FCA7A",
        barPercentage: 0.5,
      },
      {
        label: i18n.t("homePage.absenceListing.distribution.work_leave"),
        data: Object.keys(absences).map((x) => absences[x].work_leave),
        backgroundColor: "#FBC62F",
        barPercentage: 0.5,
      }
    ]
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div className="pl-0 w-[555px] h-[200px] lg:h-[380px] xl:h-[400px] 2xl:h-[437px] w-[1146px] lg:w-full xl:w-full 2xl:w-[555px]" id="users-readjustments-evolution" >
      <div className="chart-container">
        <div className="border w-full rounded-t h-[92px] px-6 pt-6 pb-4 bg-white">
          <p className="font-sans text-xl/6 font-semibold text-left text-black-report mb-2" >
            {i18n.t("homePage.absenceListing.distribution.title")}
          </p>
          <p className="font-sans text-sm/5 font-normal text-left text-default-gray-2" >
            {i18n.t("homePage.absenceListing.distribution.subtitle")}
          </p>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className="border-l border-r border-b pl-5 pr-5 pb-5 rounded-b m-0 lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white"
          id="chart-absence-graphic"
        >
          {
            loading ? 
            <LoadingPage
              customHeight="h-[200px] lg:h-[380px] xl:h-[400px] 2xl:h-[437px]"
              customWidth="w-full"
              customImageClass={"w-10 h-10"}
              customTextClass={'hidden'}
            /> : (
              <Bar
                options={barOptions}
                data={data}
                plugins={[customLegend]}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}
