import Cookie from "js-cookie";

export function loginRequest(email, password, mmAuth = false, redirection = "/pessoas") {

  const url = mmAuth ? `/api/v1/mmlogin` : `/api/v1/login`;
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "X-CSRF-Token": csrf },
    body: JSON.stringify({ session: { email, password } }),
  };
  const loginUserRequest = async () => {
    const responseReq = await fetch(url, requestOptions).then(
      async (response) => {
        const data = await response.json();

        if (response.ok) {
          mmAuth
            ? Cookie.set("mmAuthorization", `Bearer ${data.token}`)
            : Cookie.set("Authorization", `Bearer ${data.token}`);
          window.location.replace(redirection);
        }
        return data.error;
      }
    );
    return responseReq;
  };

  return loginUserRequest();
}
