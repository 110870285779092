import { createConsumer } from '@rails/actioncable';

let consumer;

function getCable() {
  if (!consumer) {
    consumer = createConsumer();
  }
  return consumer;
}

export default getCable;
