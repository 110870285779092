import React, { useState, useEffect } from 'react'
import RemunerationSumary from "#components/homePage/financialGraphics/RemunerationSumary"
import AllowancesDistribution from "#components/homePage/financialGraphics/AllowancesDistribution"
import LoadingPage from "#components/navigation/LoadingPage";
import fetchPayrollEvolution from "#requests/users/fetchPayrollEvolution"

export default function RemunerationsDistributions() {
  const [remunerationsData, setRemunerationsData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchPayrollEvolution()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setRemunerationsData(parsedResponse)
        setloading(false)
      });
  }, []);

  return(
    <div>
      {
        loading ?
          <LoadingPage
            customHeight="h-[200px] lg:h-[380px] xl:h-[400px] 2xl:h-[437px]"
            customWidth="w-full"
            customImageClass={"w-10 h-10"}
            customTextClass={'hidden'}
          /> : (
          <div className="w-full h-full flex flex-wrap gap-3">
            <RemunerationSumary remunerationsData={remunerationsData} />
            <AllowancesDistribution remunerationsData={remunerationsData} />
          </div>
        )
      }
    </div>
  )
}
