import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom'
import { loginRequest } from "#requests/loginRequest";
import i18n from "#translate/i18n";

import mailIcon from "#images/login-email-icon.svg"
import lockIcon from "#images/login-lock-icon.svg"
import arrowRight from "#images/arrow-right.svg"
import eyeInvisible from  "#images/login-eye-invisible-outlined.svg"
import eyeVisible from  "#images/login-eye-outlined.svg"
import CloseButton from "#images/buttons/button_error_red.svg";

export default function UserFormLogin() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [openDeniedAcessMessage, setOpenDeniedAcessMessage] = useState(false)

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const accessStatus = searchParams.get('status')
    if (accessStatus === 'denied') {
      setOpenDeniedAcessMessage(true)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('')
    setOpenDeniedAcessMessage(false)
    const responseLogin = await loginRequest(email, password);
    responseLogin === 'access denied' ? setOpenDeniedAcessMessage(true) : setErrorMessage(responseLogin);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="items-center mx-12 2xl:mx-20 mt-12"
      >
        {openDeniedAcessMessage && (
          <div className="bg-support-error-background rounded p-5 flex flex-row mb-8">
            <button
              onClick={() => setOpenDeniedAcessMessage(false)}
              className="justify-center flex-none rounded-md flex items-start w-5 h-full"
            >
              <img id='error_message' src={CloseButton} className="w-4 h-4 mt-0.5" alt="not_found"/>
            </button>

            <div className="flex flex-col ml-4">
              <p className="font-semibold text-sm text-dark-gray-scale">{ i18n.t("login.not_found_title") }</p>
              <span className="font-normal text-sm text-dark-gray-scale">{ i18n.t("login.not_found_subtitle") }</span>
            </div>
          </div>
        )}

        <div className="flex flex-col w-full text-title-gray text-left">
          <label
            htmlFor="login-form-email"
            className="mb-1.5 font-medium text-sm"
          >
            { i18n.t("login.email") }
          </label>

          <div className="mb-3 group">
            <img
              src={mailIcon}
              alt="Mail icon"
              className="absolute w-5 mt-3.5 ml-3 group-has-[:placeholder-shown]:login-icon-filter"
            />

            <input
              type="text"
              id="login-form-email"
              value={ email }
              placeholder={ i18n.t("login.email_placeholder") }
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              className={ `${ errorMessage && "ring-red-500 ring-2" } text-base placeholder-placeholder-gray border border-border-gray
                          text-default-gray-2 rounded w-full py-2.5 pl-10 pr-3.5` }
            />
          </div>

          <label
            htmlFor="login-form-password"
            className="mb-1.5 font-medium text-sm"
          >
            { i18n.t("login.password") }
          </label>
          <div 
            className="mb-3 group"
          >
            <img
              src={lockIcon}
              alt="Lock icon"
              className="absolute w-5 mt-3 ml-3 group-has-[:placeholder-shown]:login-icon-filter"
            />

            <button type="button" onClick={() => { setVisible(!visible)}} className=" absolute right-16 2xl:right-24">
              <img
                className="w-5 mt-3 group-has-[:placeholder-shown]:login-icon-filter"
                src={ `${ visible ? eyeVisible : eyeInvisible }` }
                alt='Eye icon'
              />
            </button>

            <input
              type={ `${ visible ? "text" : "password" }` }
              id="login-form-password"
              value={ password }
              placeholder={ i18n.t("login.password_placeholder") }
              onChange={ (event) => setPassword(event.target.value) }
              className={ `${ errorMessage && "ring-red-500 ring-2" } text-base placeholder-placeholder-gray border border-border-gray
                          text-default-gray-2 rounded w-full py-2.5 pl-10 pr-12` }
            />
          </div>

          <span
            name="validationError"
            className="items-center font-medium tracking-wide text-red-500 text-xs mt-1"
          >
            { errorMessage && i18n.t(`errors.${errorMessage}`) }
          </span>
        </div>

        <button
          type="submit"
          id="login-button"
          className="flex mt-12 mb-10 justify-center gap-3 font-semibold w-full rounded text-lg text-white bg-rebase-blue py-3"
        >
          { i18n.t("login.access") }
          <img className="mt-[1px]" src={ arrowRight } alt='Arrow Right'/>
        </button>
      </form>
    </>
  );
}
