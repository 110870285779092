import axios from "axios";

export default function updateUserMessageStatus({messageId}) {
  const url = `/api/v1/messages/${messageId}/shift_status`

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "PUT",
      headers: { "X-CSRF-Token": csrf },
    };
  };

  const options = requestOptions()

  return axios({ url, ...options })
}