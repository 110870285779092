import React, { useState, useEffect, useContext } from 'react';
import i18n from "#translate/i18n";
import AboutTab from '#components/user/profile/AboutTab';
import InfoTab from '#components/user/profile/InfoTab';
import BankCompanyTab from '#components/user/profile/BankCompanyTab';
import RemunerationTab from '#components/user/profile/RemunerationTab';
import DocumentsTab from '#components/user/profile/DocumentsTab';
import HistoricTab from '#components/user/profile/HistoricTab';

import { UserContext } from "#providers/UserContextProvider"

const tabs = [
  { label: i18n.t('userProfile.about'), component: AboutTab, tag: 'about' },
  { label: i18n.t('userProfile.info'), component: InfoTab, tag: 'info' },
  { label: i18n.t('userProfile.bank_company'), component: BankCompanyTab, tag: 'bankCompany' },
  { label: i18n.t('userProfile.remuneration'), component: RemunerationTab, tag: 'remuneration' },
  { label: i18n.t('userProfile.documents'), component: DocumentsTab, tag: 'documents' },
  { label: i18n.t('userProfile.historic'), component: HistoricTab, tag: 'historic' }
];

export default function Tabs({ userData, handleOpenedDrawersFromProfile }) {
  const [active, setActive] = useState(tabs[0]);

  const { profileTabName, setProfileTabName, } = useContext(UserContext)

  useEffect(() => {
    if (profileTabName) {
      const tabIndex = tabs.findIndex((tab) => tab.tag === profileTabName)
      setActive(tabs[tabIndex])
    }
  }, [profileTabName]);

  const ActiveComponent = active.component;

  return (
    <div className="flex flex-col pt-3">
      <div className="grid grid-cols-6">
        {tabs.map(tab => (
          <button
            key={tab.label}
            id={`tab-${tab.tag}`}
            className={`text-sm py-3.5 leading-5 font-medium ${active === tab ? 'border-b-2 border-rebase-blue font-bold text-rebase-blue' : 'text-hobby-gray'
              }`}
            onClick={() => { setActive(tab), setProfileTabName(tab.tag) }}
          >
            <span>{tab.label}</span>
          </button>
        ))}
      </div>
      <div>
        <ActiveComponent
          userData={userData}
          handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
        />
      </div>
    </div>
  );
}
