/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from "react";
import i18n from "#translate/i18n";

import UserAbsence from "#components/homePage/absences/UserAbsence";
import LoadingPage from "#components/navigation/LoadingPage";
import fetchAbsencesList from "#requests/users/fetchAbsencesList"


export default function AbsencesList() {
  const [absences, setAbsences] = useState("")
  const [loading, setLoading] = useState(true)

  const thClasses = "bg-light-gray uppercase font-semibold font-sans text-xs/[18px] text-left text-default-gray-2";
  const tdClasses = "px-3 py-2 bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)] text-default-gray-2 text-sm";

  useEffect(() => {
    fetchAbsencesList()
      .then((response) => response.json())
      .then((parsedResponse) => {
        const currentAbsences = Object.values(parsedResponse).flat()
        const absencesSortedStartDates = currentAbsences.sort((a, b) => {
          const dateA = new Date(a.start_date);
          const dateB = new Date(b.start_date);
          return dateA - dateB;
        });
        setAbsences(absencesSortedStartDates)
        setLoading(false)
      });
  }, [])

  return (
    <>
     {
        loading ?
          <LoadingPage
            customHeight="h-full"
            customWidth="w-full"
            customImageClass={"w-10 h-10"}
            customTextClass={'hidden'}
          /> : (
            <div className="bg-white rounded border border-solitude w-[1146px] lg:w-full xl:w-full 2xl:w-[calc(100%-567px)]">
              <div className="border-b w-full rounded-t h-[90px] px-6 pt-6 pb-4 bg-white">
                <p className="font-sans text-xl/6 font-semibold text-left text-black-report mb-2">
                  {i18n.t("homePage.absenceListing.title")}
                </p>
                <p className="font-sans text-sm/5 font-normal text-left text-default-gray-2">
                  {i18n.t("homePage.absenceListing.subtitle")}
                </p>
                {/* <EvolutionMenu /> */}
              </div>
              <div className="p-6 w-full h-[345px]">
                <div className="w-full border border-border-gray rounded overflow-x-auto">
                  <table id="table-absence" className="w-full table-fixed">
                    <thead className="sticky top-0 bg-light-gray z-10">
                      <tr className="whitespace-nowrap px-2 h-9 drop-shadow-[0px_-1px_0px_0px_rgba(212,219,223,1)] w-full inline-table">
                        <th className={`${thClasses} w-2/6 px-3 py-2`}>
                          {i18n.t("homePage.absenceListing.user")}
                        </th>
                        <th className={`${thClasses} w-1/6 px-3 py-2`}>
                          {i18n.t("homePage.absenceListing.client")}
                        </th>
                        <th className={`${thClasses} w-1/6 px-3 py-2`}>
                          {i18n.t("homePage.absenceListing.reason")}
                        </th>
                        <th className={`${thClasses} w-1/6 px-3 py-2`}>
                          {i18n.t("homePage.absenceListing.startDate")}
                        </th>
                        <th className={`${thClasses} w-1/6 px-3 py-2`}>
                          {i18n.t("homePage.absenceListing.returnDate")}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div className="w-full max-h-64 overflow-y-auto border-t border-border-gray">
                    <table className="w-full table-fixed rounded-b">
                      <tbody className="bg-white w-full inline-table">
                        {absences.map((absence, index) => (
                          <UserAbsence key={index} absence={absence} row={index} tdClasses={tdClasses} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
}
