
const translations = {
  title: "Pedido de Descanso Remunerado",
  subtitle: "Adicione um novo pedido de descanso remunerado para",
  year_option: "Selecione uma das opções",
  period_limit: "Limite de descansos remunerados atingido para o ano",
  start_date: "Início do descanso remunerado",
  return_date: "Retorno ao trabalho",
  total_business_days_title: "Total de dias úteis solicitados",
  total_business_days: {
    1: "1 dia",
    2: "2 dias",
    3: "3 dias",
    4: "4 dias",
    5: "5 dias",
    6: "6 dias",
    7: "7 dias",
    8: "8 dias",
    9: "9 dias",
    10: "10 dias",
    11: "10 dias + 1 dia de bônus"
  },
  current_available_days: "Quantidade de dias disponíveis",
  current_available_days_placeholder: 0,
  bonus_added_title: "Com pagamento de bônus?",
  bonus_added: {
    true: "Sim",
    false: "Não",
  },
  salary_percentage: "Percentual do bônus em relação à remuneração",
  bonus_value: "Valor do bônus desc. remunerado",
  notes: "Observações",
  notes_placeholder: "Se houver, adicione informações relevantes sobre este pedido",
  total_business_days_placeholder: "0",
  forms_error: {
    presence: "O preenchimento deste campo é obrigatório",
    time_sequence: "Período inválido, verifique dias selecionados e início do descanso remunerado",
    min_today: 'A data de retorno não pode ser igual ou anterior à data de início do descanso remunerado',
    time_interval: "Período inválido, já cadastrado previamente",
    next_month: 'Dia de início do descanso remunerado precisa ser a partir do primeiro dia do próximo mês',
    date_max: 'Dia de início do descanso remunerado não pode ser posterior a 31 de Julho do ano seguinte ao de referência',
    current_year_min: 'A data de início não pode ser anterior à data de hoje',
    next_year_min: 'A data de início não pode ser anterior ao início do ano selecionado',
  },
  messageForm: {
    error: {
      basic_server_error: "Preenchimento inválido",
      start_date: {
        'já_está_em_período_de_descanso_remunerado': 'já possui descanso remunerado cadastrado para esse período.',
        "não_é_possível_atualizar_a_data_de_início_para_o_mês_corrente_se_o_dia_de_atualização_não_for_o_primeiro_dia_do_mês": "Para configurar o início do descanso remunerado escolha uma data a partir do primeiro dia do próximo mês",
      },
      general_deadline_for_creating: {
        "não_é_permitido_criar_um_descanso_remunerado_após_o_primeiro_dia_do_mês_solicitado": "Para configurar o início do descanso remunerado escolha uma data a partir do primeiro dia do próximo mês.",
      },
    },
  },
}

export { translations }
