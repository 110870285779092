import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const PsychologicalSupportBaseSchema = Yup.object().shape({
  monthly_sessions: Yup.string().typeError(errorLabel.select_option).required(i18n.t("psychological_support.forms_error.presence")),
  duration: Yup.string().typeError(errorLabel.select_option).required(i18n.t("psychological_support.forms_error.presence")),
  min_sessions_start_date: Yup.date()
                              .nullable()
                              .typeError(i18n.t("psychological_support.forms_error.presence"))
                              .required(i18n.t("psychological_support.forms_error.presence")),
  sessions_start_date: Yup.date()
                          .nullable()
                          .when("min_sessions_start_date", {
                            is: (min_sessions_start_date) => !!min_sessions_start_date,
                            then: Yup.date()
                                     .typeError(i18n.t("psychological_support.forms_error.presence"))
                                     .required(i18n.t("psychological_support.forms_error.presence"))
                                     .min(Yup.ref('min_sessions_start_date'), i18n.t("psychological_support.forms_error.sessions_start_date")),
                            otherwise: Yup.date()
                                          .typeError(i18n.t("psychological_support.forms_error.presence"))
                                          .required(i18n.t("psychological_support.forms_error.presence"))
                          }),
  notes: Yup.string(),
});

export default PsychologicalSupportBaseSchema;

