/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"

import PeopleStatuses from "#components/homePage/usersStatuses/PeopleStatuses"
import RemunerationsDistributions from "#components/homePage/financialGraphics/RemunerationsDistributions"
import ReadjustmentsDistribution from "#components/homePage/financialGraphics/ReadjustmentsDistribution"
import HealthcareAdhesion from "#components/homePage/financialGraphics/HealthcareAdhesion"
import WorkFieldDistribution from "#components/homePage/financialGraphics/WorkFieldDistribution"
import UsersUpcomingEvents from "#components/homePage/upcomingEvents/UsersUpcomingEvents"
import AbsencesDistributions from "#components/homePage/absences/AbsencesDistributions"
import UsersCompanyFlowGraphic from "#components/homePage/UsersCompanyFlowGraphic"
import Hand from "#images/hand-top.svg";

import { UserFormsContext } from "#providers/UserFormsProvider"

import userSessionRequest from "#requests/userSessionRequest"

export default function HomePage() {
  const { openUserEditForms, openNewUserForm } = useContext(UserFormsContext)

  const [authenticatedUser, setAuthenticatedUser] = useState({})

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      return await userSessionRequest()
    }

    getAuthenticatedUser().then(data => {
      setAuthenticatedUser(data);
    })
  }, [])

  return (
    <div>
      {
        authenticatedUser?.role === "admin" && (
          <div className={`${openUserEditForms || openNewUserForm ? "hidden" : "ml-5 overflow-hidden mt-8 mr-5"}`} id="home-page-div" >
            <section id="home-page-header" className={`${openUserEditForms || openNewUserForm ? "hidden" : ""}`}>
              <div className="relative flex justify-between items-center w-fit">
                <h1 className="flex font-sans font-bold text-3xl text-black-report grow">Olá, {authenticatedUser.name?.split(" ")?.[0]}</h1>
                <img className="w-[30px] h-[30px] ml-2" src={Hand} alt="hand icon" />
              </div>
            </section>
            <section
              id="home-page-statuses"
              className={`flex-shrink-0 ${openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 overflow-auto"}`}
            >
              <PeopleStatuses />
            </section>
            <section
              id="home-page-payroll-graphics"
              className={`flex-shrink-0 ${openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 mb-5 h-auto"}`}
            >
              <RemunerationsDistributions />
            </section>
            <section
              className={`flex-shrink-0 ${openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 mb-5 h-auto grid grid-cols-3 gap-x-3"}`}
            >
              <ReadjustmentsDistribution />
              <HealthcareAdhesion />
              <WorkFieldDistribution />
            </section>
            <section
              className={`${openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 mb-5 h-auto"}`}
            >
              <UsersCompanyFlowGraphic />
            </section>
            <section
              className={`${openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 mb-5 h-auto"}`}
            >
              <UsersUpcomingEvents />
            </section>
            <section
              id="home-page-absences"
              className={openUserEditForms || openNewUserForm ? "border-none hidden" : "mt-8 mb-5 h-auto"}
            >
              <AbsencesDistributions />
            </section>
            <div className="w-hp-payroll-evolution w-hp-allowance-distribution hidden" />
          </div>
        )
      }
    </div>
  )
}
