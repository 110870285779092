import React, { useContext } from 'react'
import i18n from "#translate/i18n"
import moment from 'moment';
import 'moment/locale/pt-br';

import moneyIcon from '#images/profile_drawer/money-icon.svg';
import calendarIcon from '#images/calendar-blue.svg';
import quintalk from '#images/events_icons/quintalk.svg';
import live_coding from '#images/events_icons/live_code.svg';
import competitions from '#images/events_icons/competitions.svg';
import party_kit from '#images/events_icons/party_kit.svg';
import onboarding from '#images/events_icons/onboarding.svg';

import { UserContext } from "#providers/UserContextProvider"

import OptionsMenu from '#components/user/profile/remunerationTab/OptionsMenu';

export default function BonusReward({
  userData,
  rewardData,
  handleOpenedDrawersFromProfile,
  setOpenRemovalConfirmation,
  setChosenObjectRemoval,
  setRewardType
}) {
  const { setObjectId } = useContext(UserContext)
  const todayStart = moment(new Date()).startOf('day')
  const paymentDate = moment(rewardData.payment_date, 'YYYY-MM-DD').startOf('day')
  const isValid = paymentDate >= todayStart
  const rewardType = rewardData?.reward_type
  const paymentValue = rewardData?.bonus_value || 0
  const icons = {
    quintalk: quintalk,
    live_coding: live_coding,
    competitions: competitions,
    party_kit: party_kit,
    onboarding: onboarding,
  }

  return (
    <>
      <div id={`user-bonus-reward-${rewardType}`} className='w-full py-4 px-0 rounded border border-solitude gap-4 grid' >
        <div className='py-0 px-4 gap-3 flex' >
          <div className='gap-3 flex w-full' >
            <div className='h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto' >
              {icons[rewardType] && (
                <div className='rounded-full shadow-timeline-icons'>
                  <img
                    src={icons[rewardType]}
                    alt="event icon"
                    className='w-12 h-12 p-1'
                  />
                </div>
              )}
            </div>
            <div className='w-[90%]'>
              <p className='font-sans text-lg/[30px] font-semibold text-left text-midnight w-[90%] absolute' >
                {i18n.t(`userProfile.remuneration_tab.latest_rewards.reward_titles.${rewardType}`)}
              </p>
              <OptionsMenu
                setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                setObjectId={setObjectId}
                setChosenObjectRemoval={setChosenObjectRemoval}
                chosenObjectData={rewardData}
                setRewardType={setRewardType}
                objectType='BonusReward'
                isValid={isValid}
                handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                userData={userData}
              />
              <div className='flex gap-2 items-center mt-8' >
                <div className='flex gap-2 mr-2'>
                  <img src={moneyIcon} alt='money_image' />
                  <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                    {Number(paymentValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </p>
                </div>
                <div className='flex gap-2'>
                  <img src={calendarIcon} alt='calendar_image' />
                  <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                    {paymentDate.format('DD/MM/YY')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
