const translations = {
  work_leave_create: {
    title: "Adicionar período de afastamento",
    subtitle: "Adicione um período de afastamento para",
    submit_button_text: "Adicionar período de afastamento",
  },
  work_leave_update: {
    title: "Editar período de afastamento",
    subtitle: "Edite um período de afastamento para",
    submit_button_text: "Salvar Edição"
  },
  work_leave: {
    title: "Adicionar período de afastamento",
    subtitle: "Adicione um período de afastamento para",
    reason: "Motivo",
    start_date: "Data de início",
    foreseen_return_date: "Data de retorno prevista",
    documentation: {
      title: "COMPROVANTE",
      upload: "ANEXE O COMPROVANTE",
      alt: "Comprovante"
    },
    notes: "Observações",
    reason_placeholder: "Ex: Licença médica",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício.",
    form_errors: {
      min_date_error: "A data de retorno não pode ser igual ou anterior à data de início do período de afastamento",
      presence: "O preenchimento deste campo é obrigatório.",
      start_date_error: "A data de início não pode ser anterior à data de hoje.",
      basic_server_error: "Preenchimento inválido",
    },
    messageForm: {
      error: {
        foreseen_return_date: {
          'um_usuário_não_pode_ter_dois_afastamentos_ativos_simultaneamente': 'já possui um período de afastamento agendado ou em curso. Se necessário, edite ou remova o período vigente.',
        },
      },
    },
  },
  workLeaveDrawer: {
    personHeader: "Período de afastamento header",
    calendar: "Calendário",
    tickSymbol: 'Adicionando'
  },
}

export { translations }
