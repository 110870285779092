/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Arrow from "#images/arrow-right.svg";

export default function CarouselSlider({
  cardComponent,
  cardsData,
  sliderRef,
  cardWidth,
}) {
  const [isSliding, setIsSliding] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const updateArrowVisibility = () => {
    const slider = sliderRef.current;
    if (slider) {
      setShowLeftArrow(slider.scrollLeft > 0);
      setShowRightArrow(slider.scrollWidth > slider.clientWidth);
    }
  };

  useEffect(() => {
    updateArrowVisibility();
  }, []);

  const moveSlides = (directionAxis) => {
    const slider = sliderRef.current;
    if (isSliding) return;
    setIsSliding(true);

    const cardWidthPlusGap = cardWidth + 12
    const cardsVisible = Math.floor(window.innerWidth / cardWidthPlusGap);

    const scrollAmount = cardsVisible * cardWidthPlusGap * directionAxis;
    const scrollPosition = (window.innerWidth + slider.scrollLeft >= slider.scrollWidth)
      ? 0 : slider.scrollLeft + scrollAmount;

    const scrollToPosition = () => {
      requestAnimationFrame(() => {
        slider.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      });
    };
    scrollToPosition(scrollPosition);

    setTimeout(() => {
      setIsSliding(false);
      updateArrowVisibility();
      if (scrollPosition === 0) {
        slider.scrollLeft = 0;
      }
    }, 600);
  };

  return (
    <div className="relative" id="carousel-slider">
      {showLeftArrow && (
        <div className="absolute inset-0 flex items-center justify-start">
          <button
            className="border-none bg-none bg-slate-400 z-[1000] disabled:bg-border-gray"
            id="carousel-back-button"
            onClick={() => moveSlides(-1)}
            disabled={isSliding} 
          >
            <img src={Arrow} alt="arrow" className="transform rotate-180 w-5 h-5" />
          </button>
        </div>
      )}
      <div
        className="flex gap-x-3 overflow-hidden"
        ref={sliderRef}
        id="slider"
      >
        {cardsData.map((data, index) => (
          <div key={`card-${index}`}>
            {cardComponent({data: data})}
          </div>
        ))}
      </div>
      {showRightArrow && (
        <div className="absolute inset-0 flex items-center justify-end" >
          <button
            className="border-none bg-none bg-slate-400 z-[1000] disabled:bg-border-gray"
            id="carousel-next-button"
            onClick={() => moveSlides(1)}
            disabled={isSliding}
          >
            <img src={Arrow} alt="arrow" className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
}
