import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import i18n from "#translate/i18n";
import LoadingPage from "#components/navigation/LoadingPage";
import fetchUsersCompanyFlow from "#requests/users/fetchUsersCompanyFlow"

export default function UsersCompanyFlowGraphic() {
  const [usersData, setUsersData] = useState("")
  const [loading, setLoading] = useState(true)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    LineController,
    BarController,
    Tooltip,
    Legend
  );

  useEffect(() => {
    fetchUsersCompanyFlow()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUsersData(parsedResponse)
        setLoading(false)
      });
  }, [])

  const total = () => {
    return Object.keys(usersData).map((x) => Number(usersData[x].total_not_dismissed))
  }
  const y2Min = () => {
    const allNumbers = total()
    if (!allNumbers?.length) { return }

    const ymin = Math.min(...allNumbers)
    return Math.floor(0.9 * ymin)
  }

  const y2Max = () => {
    const allNumbers = total()
    if (!allNumbers?.length) { return }

    const yMax = Math.max(...allNumbers)
    return Math.ceil(yMax * 1.1)
  }

  const data = {
    labels: Object.keys(usersData).map((x) => i18n.t(`homePage.month.${x}`)),
    datasets: [
      {
        type: 'bar',
        label: i18n.t('homePage.usersCompanyFlow.dismissal'),
        data: Object.keys(usersData).map((x) => usersData[x].total_dismissed),
        backgroundColor: '#00C7F2',
        barPercentage: 0.8,
        categoryPercentage: 0.7,
        yAxisID: 'y',
      },
      {
        type: 'bar',
        label: i18n.t('homePage.usersCompanyFlow.hiring'),
        data: Object.keys(usersData).map((x) => usersData[x].total_hired),
        backgroundColor: '#0FCA7A',
        barPercentage: 0.8,
        categoryPercentage: 0.7,
        yAxisID: 'y',
      },
      {
        type: 'line',
        label: i18n.t('homePage.usersCompanyFlow.total'),
        data: total(),
        borderColor: "#F7A23B",
        fill: true,
        backgroundColor: "#F7A23B",
        yAxisID: 'y2',
        pointRadius: 0.1,
        pointHoverRadius: 5,
      },
    ],
  };

  const customLegendHeightPlugin = {
    id: 'legendMargin',
    afterInit(chart, _args, plugins) {
      const origFit = chart.legend.fit;
      const margin = plugins.margin || 10
      chart.legend.fit = function fit() {
        if(origFit) {
          origFit.bind(chart.legend)();
          this.height += margin;
        }
      };
    },
  };

  const options = {
    locale: "pt-BR",
    layout: {
      padding: {
        top: -4,
        bottom: 5,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#72777B',
          padding: 8
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        title: {
          display: true,
          text: i18n.t('homePage.usersCompanyFlow.xAxis'),
          padding: {
            top: 20
          },
          color: '#485668',
          font: {
            size: 12,
            weight: 'normal'
          }
        },
        border: {
          color: '#E7EAEE'
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: i18n.t("homePage.usersCompanyFlow.yAxis"),
          color: "#485668",
          font: {
            size: 12,
            weight: "normal"
          },
        },
        ticks: {
          beginAtZero: true,
          color: "#72777B",
          padding: 8,
          stepSize: 1
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        border: {
          color: '#E7EAEE'
        },
      },
      y2: {
        type: 'linear',
        position: 'right',
        min: y2Min(),
        suggestedMax: y2Max(),
        title: {
          display: true,
          text: i18n.t("homePage.usersCompanyFlow.yAxis"),
          color: "#485668",
          font: {
            size: 12,
            weight: "normal"
          },
        },
        ticks: {
          beginAtZero: false,
          color: "#72777B",
          padding: 8,
          stepSize: 1
        },
        border: {
          color: '#E7EAEE'
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 24,
          fullSize: true,
          color: '#2D2D2D',
        },
      },
    },
  };

  return (
    <div className="pl-0 w-full" id="users-readjustments-evolution" >
      <div className="chart-container w-full">
        <div className="border w-full rounded-t h-[92px] px-6 pt-6 pb-4 bg-white">
          <p className="font-sans text-xl/6 font-semibold text-left text-black-report mb-2" >
            {i18n.t("homePage.usersCompanyFlow.title")}
          </p>
          <p className="font-sans text-sm/5 font-normal text-left text-default-gray-2" >
            {i18n.t("homePage.usersCompanyFlow.subtitle")}
          </p>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className="w-full border-l border-r border-b pl-5 pr-5 pb-5 rounded-b m-0 lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white"
          id="chart-company-flow-graphic"
        >
          {
            loading ?
              <LoadingPage
                customHeight="h-full"
                customWidth="w-full"
                customImageClass={"w-10 h-10"}
                customTextClass={'hidden'}
              /> : (<Bar data={data} options={options} plugins={[customLegendHeightPlugin]} />)
          }
        </div>
      </div>
    </div>
  )
}
