const translations = {
  email: "Email",
  password: "Senha",
  access: "Acessar",
  email_placeholder: "Digite seu email",
  password_placeholder: "Digite sua senha",
  title: "Olá, boas vindas ao Rebase Pessoas!",
  subtitle: "Entre com os seus dados e acesse",
  not_found_title: "Usuário não cadastrado!",
  not_found_subtitle: "Não encontramos nenhuma conta de usuário associada a este e-mail. Por favor, verifique se o e-mail e a senha estão corretos."
}

export { translations }
