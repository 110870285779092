import React from 'react';
import i18n from "#translate/i18n";
import Title from '#components/user/profile/Title'
import CompanyRecords from '#components/user/profile/aboutTab/CompanyRecords'
import HobbiesList from '#components/user/profile/aboutTab/HobbiesList';
import PreferenceItens from '#components/user/profile/aboutTab/PreferenceItens';
import AboutIcon from '#images/about-icon.svg'
import LuggageIcon from '#images/luggage-icon.svg'
import HearthIcon from '#images/hearth-icon.svg'
import PreferenceIcon from '#images/preference-icon.svg'

export default function AboutTab({ userData }) {
  const personalPreference = userData.personal_preference;
  const hobbies = userData.hobbies
  const clients = userData.clients;
  const companyHistoryRecords = userData.company_history_records;
  const preferredKeys = [
    'preferred_movies_series',
    'preferred_bands_singers',
    'wish_list',
    'likes_social_media',
    'favorite_color',
    'day_night_preference',
    'preferred_sport',
    'preferred_club',
    'preferred_videogame',
    'preferred_foods',
    'preferred_fruits',
    'preferred_beverages',
    'preferred_beers',
    'preferred_juices',
    'avoided_foods',
    'best_trip'
  ]

  return (
    <div id="profile_about_tab">
      {personalPreference?.about_you && (
        <>
          <Title
            title={i18n.t('userProfile.about_me')}
            icon={AboutIcon}
          />
          <p className="text-info-gray text-sm font-normal leading-6 text-left p-4 px-5">
            { personalPreference.about_you }
          </p>
        </>
      )}

      {clients?.length > 0 && companyHistoryRecords?.length > 0 && (
        <>
          <Title
            title={i18n.t('userProfile.history_records')}
            icon={LuggageIcon}
          />
          <CompanyRecords
            clients={clients}
            companyHistoryRecords={companyHistoryRecords}
          />
        </>
      )}

      {hobbies?.length > 0 && (
        <>
          <Title
            title={i18n.t('userProfile.hobbies')}
            icon={HearthIcon}
          />
          <HobbiesList
            hobbies={hobbies}
          />
        </>
      )}

      {preferredKeys.some(key => personalPreference && personalPreference[key]) && (
        <>
          <Title
            title={i18n.t('userProfile.preferences')}
            icon={PreferenceIcon}
          />
          <PreferenceItens
            personalPreference={personalPreference}
          />
        </>
      )}

    </div>
  );
}