const translations = {
  title: "Notificações",
  read: "Lidas",
  unread: "Não lidas",
  editButton: {
    expenseAssistanceExpiring: "Editar ajuda de custo"
  },
  messageTitle: {
    expenseAssistanceExpiring: "Vencimento de ajuda de custo",
  },
  expenseAssistanceExpiring: {
    title: "Vencimento de ajuda de custo",
    text: 'A recorrência da ajuda de custo <strong>{{reason}}</strong> de <strong>{{name}}</strong> vence em {{day}} de {{month}} de {{year}}.',
    editExpenseAssistance: "Editar ajuda de custo"
  },
  psychologicalSupportExpiring: {
    text: 'As <strong>{{sessionNumber}} Sessões mensais</strong> do auxílio psicológico de <strong>{{name}}</strong> vencem em {{day}} de {{month}} de {{year}}.',
  },
  markAsRead: "Marcar como lida",
  markAsUnread: "Marcar como não lida",
  monthNames: {
    1: "janeiro",
    2: "fevereiro",
    3: "março",
    4: "abril",
    5: "maio",
    6: "junho",
    7: "julho",
    8: "agosto",
    9: "setembro",
    10: "outubro",
    11: "novembro",
    12: "dezembro",
  },
}

export { translations }
