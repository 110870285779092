const translations = {
  hiring_date: "Data de início na Rebase",
  exit_date: "Data de saída da Rebase",
  exit_date_info: "considerar o último dia de trabalho da pessoa na Rebase",
  peopleHeader: "Desligamento",
  access: 'Acessos',
  status: 'Status',
  reason: {
    title: "Motivo",
    dismissal_collaborator: "Desligamento pelo colaborador",
    dismissal_rebase: "Desligamento pela Rebase",
    others: "Outros"
  },
  notes_reason: 'Especifique o motivo do desligamento',
  exit_meeting_date: "Data da reunião de desligamento",
  company_feedback: "Feedback sobre a empresa",
  general_notes: "Anotações gerais",
  documentation: {
    label: "Documentação",
    title: "Documentos",
    alt: "Documentos",
  },
  access_title: "Acessos e periféricos",
  access_and_assets_attributes: {
    status: {
      revoked: 'Revogado',
      not_applicable: 'Não se aplica',
    },
    office_access: 'Biometrias escritório',
    figma_access: 'Figma',
    github_access: 'Github',
    gmail_access: 'Gmail',
    mattermost_access: 'Mattermost',
    swile: 'Swile',
    trello_access: 'Trello',
  },
  extended_allowances_attributes: {
    allowance_name: {
      title: 'Nome do benefício',
      home_office: 'Auxílio home office',
      meal: 'Auxílio alimentação',
      healthcare: 'Prêmio saúde',
      psychological_support: 'Auxílio psicológico',
      disabled: ' - não possui este benefício',
    },
    has_extended_allowances_attributes: {
      title: "O Rebaser continuará recebendo algum benefício após rescisão?",
      yes: "Sim",
      no: "Não"
    },
    end_date: "Manter o benefício até:",
  },
  placeholder: {
    company_feedback: "Ex. Registre os feedbacks do Rebaser",
    general_notes: "Ex. Participaram da reunião: Jaqueline Sousa, Carla Muniz e Fábio (sócio)",
    access_status: "Selecione o status de cada acesso que o Rebaser possuir.",
    others_access: "Ex. Notebook, VPN cliente",
  },
  others_access: "Outros acessos",
  amounts_receivable: "Valores a Receber",
  amounts_receivable_subtitle: "Informe os valores que serão pagos ao Rebaser no momento da rescisão",
  termination_balance: "Saldo rescisório",
  invoice_total_value: "Valor da Nota fiscal",
  installments_number: {
    title: "Forma de pagamento",
    1: "Pagamento integral",
    2: "2 parcelas",
    3: "3 parcelas",
    4: "4 parcelas",
    5: "5 parcelas",
    6: "6 parcelas",
  },
  payment_date: "Data de pagamento",
  installment_value: 'Valor da parcela',
  benefits: "Benefícios",
  user_dismissal_create: {
    title: "Desligamento",
    subtitle: "Registre o desligamento de",
    submit_button_text: "Registrar desligamento",
  },
  user_dismissal_update: {
    title: "Edição de desligamento",
    subtitle: "Edite o desligamento de",
    submit_button_text: "Salvar edição"
  },
  messageForm: {
    error: {
      presence: 'O preenchimento deste campo é obrigatório',
      invoice_total_must_equal_installments_sum: 'Total deve ser equivalente à soma das parcelas',
      date_error: 'A data de saída não pode ser anterior ao mês vigente.',
      allowance_date_error: 'A data final para extenção dos benefícios deve ser posterior ao mês da data de desligamento e não pode ser retroativa.'
    },
  },
}

export { translations }
