import React, { useEffect } from "react"
import { Trans } from "react-i18next"
import i18n from "#translate/i18n"

import SuccessCheck from "#images/bonus_drawer/success_check.svg"
import CloseButton from "#images/bonus_drawer/close_button.svg"

export default function SuccessMessage({ setShowMessage, newMessage }) {
  const userName = newMessage.userName
  const source = newMessage.source
  const additionalData = newMessage.additionalData

  const closeMessage = () => {
    setShowMessage(messages => messages.filter(message => message !== newMessage ))
  }
  
  useEffect(() => {
    setTimeout(() => { closeMessage() }, 10000);
  }, [newMessage]);

  return (
    <div className="rounded w-[437px] h-32 mb-1.5 shadow-[0_1px_2px_rgba(0,0,0,0.3),0_2px_6px_rgba(0,0,0,0.15)] bg-white text-dark-gray-scale"
      id='general-success-message'
    >
      <div className="flex flex-row w-full h-full" id='general-success-message-body'>
        <div className="bg-light-support-positive h-full w-[100px] flex flex-row items-center" id='general-success-message-body-div'>
          <img src={SuccessCheck} alt={i18n.t('drawerResponses.success.check')} className="mx-auto" id='general-success-message-body-image' />
        </div>

        <div className="w-full p-4" id='general-success-message-text'>
          <div className="flex justify-between w-full">
            <h1 className="font-semibold font-sans text-base" id='general-success-message-h1'>{i18n.t(`drawerResponses.success.${source}.title`)}</h1>
            <button  onClick={closeMessage} className="w-6 h-6" id='general-success-message-close'>
              <img src={CloseButton} alt={i18n.t('drawerResponses.success.check')} id='general-success-message-close-img' />
            </button>
          </div>
          <p className="font-sans font-normal text-sm mt-1.5" id='general-success-message-p'><Trans i18nKey={`drawerResponses.success.${source}.content`} values={{ name: userName, additionalData: additionalData }} /></p>
        </div>
      </div>

    </div>
  )
}