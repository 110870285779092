import React, { useState } from "react";

export default function MessageText({ i18nText, values }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const replaceValues = (originalStr, values) => {
    let str = originalStr
    Object.entries(values).forEach(([key, value]) => {
      const placeholder = `{{${key}}}`;
      str = str.replace(new RegExp(placeholder, 'g'), value);
    });
    return str
  }

  const getStrongTagIntervals = (text) => {
    const intervals = [];
    let regex = /<strong>/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const start = match.index;
      const end = start + '<strong>'.length;
      intervals.push({ start, end, ref: "open" });
    }

    regex.lastIndex = 0;
    regex = /<\/strong>/g;

    while ((match = regex.exec(text)) !== null) {
      const start = match.index;
      const end = start + '</strong>'.length;
      intervals.push({ start, end, ref: "close" });
    }

    return intervals.sort((a, b) => a.start - b.start)
  }

  const safeSliceString = (originalStr, values, end) => {
    let str = replaceValues(originalStr, values)
    const cloneStr = str
    const intervals = getStrongTagIntervals(cloneStr)
    const newStr = cloneStr.replaceAll("<strong>", "").replaceAll("</strong>", "")
    const sliced = newStr.slice(0, end)
    let joiningString = sliced
    intervals.forEach(interval => {
      let index = interval.start
      if (index > joiningString.length) { return }
      if (interval.ref === "open") {
        joiningString = joiningString.slice(0, index) + "<strong>" + joiningString.slice(index);
      }
      if (interval.ref === "close") {
        joiningString = joiningString.slice(0, index) + "</strong>" + joiningString.slice(index);
      }
    })
    const resultOpenedStrong = (joiningString.match(/<strong>/g) || []).length;
    const resultClosedStrong = (joiningString.match(/<\/strong>/g) || []).length;
    if (resultOpenedStrong > resultClosedStrong) {
      joiningString += '</strong>';
    }
    return joiningString;
  };

  const sliceParts = (toSlice) => {
    return toSlice.split(/(<strong>.*?<\/strong>)/g);
  }

  const shortText = safeSliceString(i18nText, values, 60)

  return (
    <div>
      {isReadMore ?
        (
          <>
            {sliceParts(shortText).map((part, index) => {
              if (part.startsWith('<strong>')) {
                return <span key={index} className="font-semibold">{part.replace(/<\/?strong>/g, '')}</span>;
              }
              return part;
            })}
          </>
        ) : (
          <>
            {sliceParts(replaceValues(i18nText, values)).map((part, index) => {
              if (part.startsWith('<strong>')) {
                return <span key={index} className="font-semibold">{part.replace(/<\/?strong>/g, '')}</span>;
              }
              return part;
            })}
          </>
        )
      }
      <button
        id="expand-message-text"
        onClick={toggleReadMore}
        className="text-border-blue"
      >
        <p className="font-normal font-sans text-sm/6">{isReadMore ? " ...ver mais" : " ver menos"}</p>
      </button>
    </div>
  );
};
