/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import i18n from "#translate/i18n";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import customizedExportSheetSchema from "#validations/customizedExportSheetSchema";

import arrayToURIQueryString from "#services/arrayToURIQueryString"
import flattenObjectToURIQuery from "#services/flattenObjectToURIQuery"

import PersonImage from "#images/people/filters/export_sheet_header.svg";
import PersonImageBackground from '#images/people/filters/export_sheet_header_background.svg';
import NotesImage from '#images/people/filters/export_sheet_subtitle.svg';
import CloseButton from '#images/add_allowances/close_icon.svg';

import PersonalData from '#components/listings/people/data/drawers/PersonalData'
import BankAccountData from '#components/listings/people/data/drawers/BankAccountData'
import PersonalPreferencesData from '#components/listings/people/data/drawers/PersonalPreferencesData'
import CompanyData from '#components/listings/people/data/drawers/CompanyData'
import SaveButton from "#components/buttons/medium/PrimaryButton"
import CancelButton from "#components/buttons/medium/TextButton"
import DivisionLine from "#components/formComponents/DivisionLine";
import SearchOptions from "#components/listings/people/data/drawers/SearchOptions"
import useOnClickOutside from '#services/useOnClickOutside';
import downloadSpreadSheetml from '#services/downloadSpreadSheetml'
import flattenAndTranslateHashWithArrayValues from "#services/flattenAndTranslateHashWithArrayValues"
import findKeysByMatchingStringValues from "#services/findKeysByMatchingStringValues"
import rebuildFlattenedHashWithArrayValues from "#services/rebuildFlattenedHashWithArrayValues"
import SelectedUsersPictures from "#components/listings/people/data/drawers/SelectedUsersPictures";
import InputText from '#components/formComponents/InputText';

import customizedDataOptions from "#requests/users/customizedDataOptions"
import CompanyHistoryData from "./CompanyHistoryData";

export default function CustomizedExportSheet({
  setOpenExportDataDrawer,
  selectedUsers
}) {
  const [submittingData, setSubmittingData] = useState(false);
  const [completeFieldsOptions, setCompleteFieldsOptions] = useState({})
  const [fieldsOptions, setFieldsOptions] = useState({})
  const [anyOptionSelected, setAnyOptionSelected] = useState(false)
  const [openSection, setOpenSection] = useState()
  const [fieldsTranslations, setFieldsTranlations] = useState()
  const [searchFieldString, setSearchFieldString] = useState('')

  const customizedExportSheetDrawer = useRef();

  const { getValues, watch, ...formMethods } = useForm({
    resolver: yupResolver(customizedExportSheetSchema),
  })

  const watchAllFields = watch()

  function findSectionsOpening(obj) {
    const result = {};
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        result[key] = hasNonEmptyArrays(obj[key]);
      }
    }
    return result;
  }

  function hasNonEmptyArrays(obj) {
    if (Array.isArray(obj) && obj.length > 0) {
      return true;
    } else if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          if (hasNonEmptyArrays(obj[key])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  useEffect(() => {
    customizedDataOptions()
      .then((response) => response.json())
      .then((options) => {
        const status = {}
        Object.keys(options).forEach((section) => status[section] = false)
        setOpenSection(status)
        const translations = flattenAndTranslateHashWithArrayValues(
          { obj: { ...options }, i18nPrefix: 'customizedDataExport.selections.sections.' }
        )
        setFieldsTranlations(translations)
        setFieldsOptions(options)
        setCompleteFieldsOptions(options)
      })
  }, [])

  useEffect(() => {
    const jsonString = JSON.stringify({ ...getValues() });
    const matches = /true/.test(jsonString);

    setAnyOptionSelected(matches)
  }, [getValues, watchAllFields])

  useEffect(() => {
    if (searchFieldString) {
      const fields = findKeysByMatchingStringValues(fieldsTranslations, searchFieldString)
      const newOptions = rebuildFlattenedHashWithArrayValues(fields)
      setFieldsOptions(newOptions)
      const primaryKeysStatus = findSectionsOpening(newOptions);
      setOpenSection(primaryKeysStatus)
    } else {
      setFieldsOptions(completeFieldsOptions)
    }
  }, [searchFieldString])

  useOnClickOutside({
    ref: customizedExportSheetDrawer,
    handler: () => { setOpenExportDataDrawer(false) },
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  });

  const onSubmit = async (data) => {
    setSubmittingData(true);

    const { file_name, ...selection_fields } = data
    const flatData = flattenObjectToURIQuery({ fields: selection_fields });
    const queryString = new URLSearchParams({ ...flatData }).toString();

    const userParams = arrayToURIQueryString({ users_ids: selectedUsers.map(user => user.id) })

    try {
      const response = await axios.get(`/api/v1/users/customized_data.xlsx?file_name=${file_name}&${userParams}&${queryString}`, { responseType: 'blob' });
      downloadSpreadSheetml({ response: response })
    } catch (error) {
      console.error('Error downloading the file', error);
    } finally {
      setSubmittingData(false)
    }
  };

  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen'>
      <div
        ref={customizedExportSheetDrawer}
        className='bg-white absolute right-0 top-0 bottom-0 rounded-none w-1/2
                    shadow-[0px_16px_24px_rgb(0,0,0,0.14),0px_6px_30px_rgb(0,0,0,0.12)'
        id='customized-filter-export-drawer'
      >
        <div className="block fixed right-0 left-1/2 bottom-0 top-0">
          <div className="block h-fit" >
            <div className={`bg-rebase-green z-1600 flex
                            justify-center h-[90px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[180px] max-h-[15vh]`}
            >
              <div className="text-center h-[95%] self-end relative left-0 top-0">
                <img
                  className="text-center h-[95%] self-end relative left-0 top-[7px]"
                  src={PersonImageBackground}
                  alt={i18n.t("customizedDataExport.image.personHeaderBackground")}
                />
                <img
                  className="text-center h-[95%] self-end absolute left-0 top-[7px]"
                  src={PersonImage}
                  alt={i18n.t("customizedDataExport.image.personHeader")}
                />
              </div>
              <button
                data-testid='close_leave_drawer_button'
                id='close_leave_drawer_button'
                type='button'
                className='absolute top-5 right-5'
                onClick={() => { setOpenExportDataDrawer(false) }}
              >
                <img
                  data-testid='close_selection_drawer_image'
                  src={CloseButton}
                  alt={i18n.t('buttons.logout')}
                  className="sm:h-5 md:h-5 lg:h-5 xl:h-6 2xl:h-7"
                />
              </button>
            </div>
            <div className='m-4 flex' >
              <div className="mt-0 mr-4 z-[1650] bg-white" >
                <img
                  src={NotesImage}
                  alt={i18n.t("customizedDataExport.image.notes")}
                />
              </div>
              <div className="mt-0 z-[1600]" >
                <h5 className="font-sans not-italic font-bold text-xl/6" >
                  {i18n.t('customizedDataExport.header.title')}
                </h5>
                <h6 className="font-sans not-italic text-sm/6 text-default-gray-2" >
                  <p className='mt-[5px]'>
                    {i18n.t('customizedDataExport.header.subtitle')}
                  </p>
                </h6>
              </div>
            </div>
          </div>
          <DivisionLine customStyle='mb-0' />
          <div className="flex flex-col px-6 pt-0 mt-0 sm:mt-2 md:mt-2 lg:mt-1 xl:mt-0 2xl:mt-0 overflow-y-auto h-[calc(100vh-290px)] lg:h-[calc(100vh-300px)] xl:h-[calc(100vh-305px] 2xl:h-[calc(100vh-347px)]">
            <div className="mt-6 z-[1600] lg:mt-2 2xl:mt-6" >
              <h5 className="font-sans not-italic font-bold text-lg/7" >
                {i18n.t('customizedDataExport.selections.title')}
              </h5>
              <h6 className="font-sans not-italic text-sm/6 text-default-gray-2" >
                <p className='mt-1 mb-6 lg:mb-2 2xl:mb-6'>
                  {i18n.t('customizedDataExport.selections.subtitle')}
                </p>
              </h6>
            </div>

            <div className="flex mb-4 lg:mb-2 2xl:mb-4" id='selected-users-pictures'>
              <h6 className="font-sans not-italic text-sm font-semibold mt-3 mr-3">
                {i18n.t('customizedDataExport.selections.peopleExporting')}
              </h6>
              <SelectedUsersPictures selectedUsers={selectedUsers} />
            </div>

            <FormProvider {...formMethods}>
              <form id={'customized-data-forms'} >
                <div className="h-full">
                  <SearchOptions classNameSearchDiv={'mt-[5px] space-y-2'} setSearchFieldString={setSearchFieldString} />
                  <PersonalData
                    fieldsPersonalDataOptions={fieldsOptions.personal_data}
                    openSection={openSection}
                    setOpenSection={setOpenSection}
                  />
                  <BankAccountData
                    fieldsBankAccountDataOptions={fieldsOptions.bank_account_data}
                    openSection={openSection}
                    setOpenSection={setOpenSection}
                  />
                  <CompanyData
                    fieldsCompanyDataOptions={fieldsOptions.company_data}
                    openSection={openSection}
                    setOpenSection={setOpenSection}
                  />
                  <CompanyHistoryData
                    fieldsCompanyHistoryDataOptions={fieldsOptions.company_history_data}
                    openSection={openSection}
                    setOpenSection={setOpenSection}
                  />
                  <PersonalPreferencesData
                    fieldsPersonalPreferencesDataOptions={fieldsOptions.personal_preferences_data}
                    openSection={openSection}
                    setOpenSection={setOpenSection}
                  />
                  <div className="w-full lg:mt-2 lg:mb-2 2xl:mt-4 2xl:mb-1">
                    <InputText
                      name='file_name'
                      placeholder={i18n.t(`customizedDataExport.file_name.placeholder`)}
                      title={i18n.t(`customizedDataExport.file_name.title`)}
                      requiredField={true}
                      className='w-full'
                    />
                  </div>
                </div >

                <div className='fixed bottom-0 right-0 w-1/2 flex flex-row bg-white h-28 lg:h-24 2xl:h-28' >
                  <div className='absolute right-2.5 left-2.5 bottom-[78px]' >
                    <DivisionLine customStyle='mb-2.5' />
                    <CancelButton
                      buttonId={`customized-filter-export-cancel-button`}
                      defaultText={i18n.t('buttons.cancel')}
                      onClick={() => setOpenExportDataDrawer(false)}
                      disabled={submittingData}
                      imagePresent={false}
                      innerDivClass={'grid'}
                      type={'button'}
                      buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 right-64`}
                    />

                    <SaveButton
                      onClick={() => formMethods.handleSubmit(onSubmit)()}
                      defaultText={i18n.t('buttons.export_people_data')}
                      submittingData={submittingData}
                      disabled={!anyOptionSelected}
                      processingText={i18n.t('buttons.exporting')}
                      buttonId={"customized-filter-export-submit-button"}
                      buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 w-48`}
                      type={'button'}
                    />
                  </div>
                </div>
              </form >
            </FormProvider >
          </div >
        </div >
      </div >
    </div>
  );
}
