import React, { useContext, useRef } from "react"
import i18n from "#translate/i18n"
import { Menu as MenuDropdown } from "@headlessui/react"

import useOnClickOutside from '#services/useOnClickOutside';

import EditIcon from "#images/edit.svg"
import BonusIcon from "#images/bonus_drawer/icon.svg"
import BonusDisabledIcon from "#images/bonus_drawer/icon-disabled.svg"
import MenuIcon from '#images/profile_drawer/menu-icon.svg';
import AddAllowancesIcon from "#images/add_allowances/icon.svg"
import AddAllowanceDisabledIcon from "#images/add_allowances/icon_disabled.svg"
import ExpenseAssistanceIcon from "#images/expense_assistance_drawer/icon.svg"
import ExpenseAssistanceDisabledIcon from "#images/expense_assistance_drawer/icon_disabled.svg"
import SalaryReadjustmentIcon from '#images/salary_readjustment_drawer/icon.svg'
import SalarayuReadjustmentDisabledIcon from '#images/salary_readjustment_drawer/icon_disabled.svg'
import WorkLeaveIcon from '#images/calendar-dark-icon.svg'
import WorkLeaveDisabledIcon from '#images/calendar-disabled-icon.svg'
import DismissalIcon from '#images/user_dismissal_drawer/user-dismissal-icon.svg'
import { useFeatureFlags } from '#providers/FeatureFlagsProvider'

import { UserFormsContext } from "#providers/UserFormsProvider"
import { UserContext } from "#providers/UserContextProvider"

export default function Menu({
  person,
  closingMenu,
  row,
  peopleLength,
  setProfileTabName,
  setOpenMenu,
  openMenu,
}) {
  const userMenu = useRef();
  const { featureFlags } = useFeatureFlags();
  const isEditDismissalEnabled = featureFlags.edit_dismissal;
  useOnClickOutside({ ref: userMenu, handler: () => { setOpenMenu?.('') }, ignoredIds: ['people-main', "options-menu", "options-menu-button", "menu-items"] });

  const { handleDrawersOpen } = useContext(UserContext)
  const { setOpenUserEditForms, setUserDataEditForms } = useContext(UserFormsContext)
  const { setObjectId } = useContext(UserContext)
  const isLastRowsOfList = (row, peopleLength) => {
    const listNumber = peopleLength - 1;
    if (peopleLength >= 13) {
      return row >= listNumber - 5;
    } else if (peopleLength >= 11) {
      return row >= listNumber - 4;
    } else if (peopleLength >= 8) {
      return row >= listNumber - 1;
    }

    return false;
  }
  const lastRowsOfTheList = isLastRowsOfList(row, peopleLength);
  const dismissalId = person.dismissal_id

  const disabledSyle = dismissalId ? 'text-disabled-gray' : ''
  return (
    <MenuDropdown as="div" className="relative" id="options-menu" ref={userMenu}>
      {({ open }) => (
        <>
          <MenuDropdown.Button as={React.Fragment} id="options-menu-button">
            <button
              className="flex items-center border rounded p-1.5 border-border-gray"
              onClick={() => {
                return open ? setOpenMenu?.('') : setOpenMenu?.(row)
              }}
            >
              <img src={MenuIcon} alt={ i18n.t('peopleListing.menu.moreOptions') } />
            </button>
          </MenuDropdown.Button>
          <MenuDropdown.Items
            className={`${openMenu === row ? "z-[1000]" : "z-10"} absolute right-0 py-1 mt-1.5 w-80 origin-top-right rounded bg-white text-default-gray-2 text-sm font-medium shadow-lg ring-1 ring-rebase-navy-blue focus:outline-none z-50 ${lastRowsOfTheList ? 'bottom-11' : ''}`}
            id="menu-items"
          >
            <MenuDropdown.Item>
              {({active}) => (
                <button
                  onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'expenseAssistance'), setProfileTabName?.('historic') }}
                  className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4 ${disabledSyle}`}
                  disabled={dismissalId}
                >
                  <img src={ dismissalId ? ExpenseAssistanceDisabledIcon : ExpenseAssistanceIcon } alt="" className='mr-3 w-6 h-6' />

                  <p>{ i18n.t('peopleListing.menu.expenseAssistance') }</p>
                </button>
              )}
            </MenuDropdown.Item>

            <MenuDropdown.Item>
              {({active}) => (
                <button 
                  onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'allowances'), setProfileTabName?.('historic') }}
                  className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4 ${disabledSyle}`}
                  disabled={dismissalId}
                >
                  <img src={ dismissalId ? AddAllowanceDisabledIcon : AddAllowancesIcon } alt="" className='mr-3 w-6 h-6' />
                  <p>{ i18n.t('peopleListing.menu.addAllowance') }</p>
                </button>
              )}
            </MenuDropdown.Item>

            <MenuDropdown.Item>
              {({active}) => (
                <button 
                  onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'workLeave'), setProfileTabName?.('historic') }}
                  className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4 ${disabledSyle}`}
                  disabled={dismissalId}
                >
                  <img src={ dismissalId ? WorkLeaveDisabledIcon : WorkLeaveIcon } alt="" className='mr-3 w-6 h-6' />

                  <p>{ i18n.t('peopleListing.menu.workLeave') }</p>
                </button>
              )}
            </MenuDropdown.Item>

            <MenuDropdown.Item>
              {({active}) => (
                <button
                  onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'bonus'), setProfileTabName?.('historic') }}
                  className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4 ${disabledSyle}`}
                  disabled={dismissalId}
                >
                  <img src={ dismissalId ? BonusDisabledIcon : BonusIcon } alt="" className='mr-3 w-6 h-6' />

                  <p>{ i18n.t('peopleListing.menu.bonus') }</p>
                </button>
              )}
            </MenuDropdown.Item>

            <MenuDropdown.Item>
              {({active}) => (
                  <button
                    onClick={() => { closingMenu?.(), setOpenUserEditForms(true), setUserDataEditForms(person) }}
                    className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4`}
                  >
                    <img src={ EditIcon } alt="" className='mr-3 w-6 h-6' />

                    <p>{ i18n.t('peopleListing.menu.edit') }</p>
                  </button>
              )}
            </MenuDropdown.Item>

            <MenuDropdown.Item>
              {({active}) => (
                <button
                  onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'salaryReadjustment'), setProfileTabName?.('historic') }}
                  className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4 ${disabledSyle}`}
                  disabled={dismissalId}
                >
                  <img src={ dismissalId ? SalarayuReadjustmentDisabledIcon : SalaryReadjustmentIcon } alt="" className='mr-3 w-6 h-6' />
                  <p>{ i18n.t('peopleListing.menu.salaryReadjustment') }</p>
                </button>
              )}
            </MenuDropdown.Item>
            { isEditDismissalEnabled ?
              (
                <MenuDropdown.Item>
                  {({active}) => (
                    <button
                      onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'userDismissal'), setProfileTabName?.('historic'), setObjectId(dismissalId) }}
                      className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4`}
                    >
                      <img src={ DismissalIcon } alt="" className='mr-3 w-6 h-6'/>
                      <p>{ i18n.t( `peopleListing.menu.${dismissalId ? 'updateUserDismissal' : 'createUserDismissal'}`) }</p>
                    </button>
                  )}
                </MenuDropdown.Item>
              ) : (
                <MenuDropdown.Item className={`${dismissalId ? 'hidden' : ''}`}>
                  {({active}) => (
                    <button
                      onClick={() => { closingMenu?.(), handleDrawersOpen(person, 'userDismissal'), setProfileTabName?.('historic') }}
                      className={`${active && 'bg-light-gray font-semibold'} w-full flex items-center py-2 px-4 2xl:py-4`} 
                    >
                      <img src={ DismissalIcon } alt="" className='mr-3 w-6 h-6'/>
                      <p>{ i18n.t( 'peopleListing.menu.createUserDismissal' ) }</p>
                    </button>
                  )}
                </MenuDropdown.Item>
              )
            }
          </MenuDropdown.Items>
        </>
      )}
    </MenuDropdown>
  )
}
