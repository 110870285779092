import { useEffect } from 'react';

export default function useOnClickOutside({ ref, handler, ignoredIds }) {
  useEffect(() => {
    const listener = (event) => {
      // Check if the click is inside the ref
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      // Function to check if any parent has an ignored ID
      const isIgnoredParent = (target) => {
        let currentElement = target;
        while (currentElement) {
          if (ignoredIds.includes(currentElement.id)) {
            return true;
          }
          currentElement = currentElement.parentElement; // Move up the DOM tree
        }
        return false;
      };

      // Check if the event target or any of its parents has an ignored ID
      if (ignoredIds?.length && (ignoredIds.includes(event.target.id) || isIgnoredParent(event.target))) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, ignoredIds]);
}

